<footer [ngStyle]="{ 'background-color': '#'+this.companyParamsService.getFooterColor() }" style="position: relative; bottom: 0px; width: 100%; padding-top: 20px;
padding-bottom: 20px;">

    <div class="container" *ngIf="this.companyParamsService!=undefined && this.companyParamsService.getCompanyParams()!=undefined">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="rowFooter" style="display: flex; flex-direction: column;">
                    <div class="small text-muted" style="flex:1; color: white !important;" [innerHTML]="this.companyParamsService.getFooterMessage()">
                    </div>
                    <br>
                    <p class="small text-muted" style="flex:1; color: white !important;">
                        Copyright {{ todayDate }} © <a href="https://galavail.com/" target="_blank">Galavail.com</a> &nbsp;All Rights Reserved.
                    </p>

                </div>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div style="flex:1;text-align: end;">
                    <a *ngIf="this.companyParamsService.getFacebookLink()!=undefined && this.companyParamsService.getFacebookLink()!=''" href="{{this.companyParamsService.getFacebookLink()}}" target="_blank" class="fa fa-facebook"></a>
                    <a *ngIf="this.companyParamsService.getTwitterLink()!=undefined && this.companyParamsService.getTwitterLink()!=''" href="{{this.companyParamsService.getTwitterLink()}}" target="_blank" class="fa fa-twitter"></a>
                    <a *ngIf="this.companyParamsService.getGoogleLink()!=undefined && this.companyParamsService.getGoogleLink()!=''" href="{{this.companyParamsService.getGoogleLink()}}" target="_blank" class="fa fa-google"></a>
                    <a *ngIf="this.companyParamsService.getLinkedinLink()!=undefined && this.companyParamsService.getLinkedinLink()!=''" href="{{this.companyParamsService.getLinkedinLink()}}" target="_blank" class="fa fa-linkedin"></a>
                    <a *ngIf="this.companyParamsService.getYoutubeLink()!=undefined && this.companyParamsService.getYoutubeLink()!=''" href="{{this.companyParamsService.getYoutubeLink()}}" target="_blank" class="fa fa-youtube"></a>
                    <a *ngIf="this.companyParamsService.getPinterestLink()!=undefined && this.companyParamsService.getPinterestLink()!=''" href="{{this.companyParamsService.getPinterestLink()}}" target="_blank" class="fa fa-pinterest"></a>
                    <a *ngIf="this.companyParamsService.getTripadvisorLink()!=undefined && this.companyParamsService.getTripadvisorLink()!=''" href="{{this.companyParamsService.getTripadvisorLink()}}" target="_blank" class="fa fa-tripadvisor"></a>
                    <a *ngIf="this.companyParamsService.getInstagramLink()!=undefined && this.companyParamsService.getInstagramLink()!=''" href="{{this.companyParamsService.getInstagramLink()}}" target="_blank" class="fa fa-instagram"></a>
                </div>
            </div>


        </div>
    </div>

</footer>
<!-- Modal Passport Number -->
<div class="modal fade" id="passport" tabindex="-1" role="dialog" aria-labelledby="ticketsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ticketsLabelPassport">Why is Passport Number required?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <p>Immigration authorities of the destination country/departure port use the passport number to verify the traveler's eligibility for entry, including visa requirements and legal permissions.</p>
            </div>
            <div class="modal-footer">
                <button id="btnCloseModalPassport" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- ./Modal -->
<!-- Modal Galapagos air tickets -->
<div class="modal fade" id="tickets" tabindex="-1" role="dialog" aria-labelledby="ticketsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ticketsLabelTickets">The significance of buying air tickets along with your cruise!</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <p>When you book the airfare along with your cruise package, it ensures seamless coordination of transportation to and from the cruise departure and arrival points. This eliminates the hassle of managing logistics, dealing with potential flight delays, and incurring additional charges.</p>
                <p>To make the most informed decision, we recommend reaching out to our dedicated sales team or contacting our customer service representatives directly.</p>
            </div>
            <div class="modal-footer">
                <button id="btnCloseModalTickets" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- ./Modal -->

<div class="padre">
    <div class="container hijo">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <span class="display-1 d-block">403 - Forbidden</span>
                <div class="mb-4 lead">Access to the specified resource has been forbidden.</div>
                <p>
                    Redirect to home page in: {{counter}}
                </p>
            </div>
        </div>
    </div>
</div>
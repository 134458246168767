<nav #header class="fixed-top">
    <div class="containerHeader" style="background-color: white;">
        <div style="width: 300px;">
            <a href="{{this.companyParamsService.getWebSite()}}" target="_blank"><img
                    src="https://www.galapagos.de/images/logo-galapagos.de.svg" class="" alt=""
                    style="max-width: 320px; height: auto;" decoding="async"></a>
        </div>
        <div class="kontakt">
            <ul class="navbar-nav">
                <li class="nav-item menuItem" style="border-right: 1px solid #aaa;"><a
                        href="https://www.galapagos.de/index.html" style="color: #9b2325;" target="_blank"><i
                            class="fa fa-home fa-lg"></i></a></li>
                <li class="nav-item menuItem" style="border-right: 1px solid #aaa;"><a href="javascript:void(0)"
                        (click)="link()">Galapagos-Kreuzfahrten</a>
                </li>
                <li class="nav-item menuItem" style="border-right: 1px solid #aaa;"><a
                        href="https://www.galapagos.de/kontakt.php" target="_blank">Kontakt</a>
                </li>
                <li class="nav-item menuItem"><a href="https://galapagos-pro.com/profis/" target="_blank">
                        <a href="https://www.galapagos.de/index.html" target="_blank"><img
                                src="../../../../../../../../assets/images/de_round.svg" style="padding: 0 5px;"></a>
                        <a href="https://www.galapagos.de/en/index.html" target="_blank"><img
                                src="../../../../../../../../assets/images/en_round.svg" style="padding: 0 5px;"></a>
                        <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=fr&_x_tr_hl=de"
                            target="_blank">
                            <img src="../../../../../../../../assets/images/fr_round.svg" style="padding: 0 5px;"></a>
                        <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=it&_x_tr_hl=de"
                            target="_blank">
                            <img src="../../../../../../../../assets/images/it_round.svg" style="padding: 0 5px;"></a>
                        <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=es&_x_tr_hl=de"
                            target="_blank">
                            <img src="../../../../../../../../assets/images/es_round.svg" style="padding: 0 5px;"></a>
                        <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=ru&_x_tr_hl=de"
                            target="_blank">
                            <img src="../../../../../../../../assets/images/ru_round.svg" style="padding: 0 5px;"></a>
                        <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=zh-CN&_x_tr_hl=de"
                            target="_blank">
                            <img src="../../../../../../../../assets/images/cn_round.svg" style="padding: 0 5px;"></a>
                    </a>
                </li>
                <li class="nav-item menuItem" *ngIf="companyParamsService.getFiltersInterest()">
                    <a class="wish" (click)="goFavorites()"
                        [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                        <i class="fa fa-heart"></i>
                    </a>
                    <span class="tooltip-text">wishlist</span>
                </li>
            </ul>
        </div>
        <div class="header-telephone">
            <p><a href="tel:+4976339399360" title="Tel. +49 7633 9399360" class="text-noline"><i
                        class="fa fa-lg fa-phone hellgrau"></i> +49 7633 9399360</a></p>
            <p><a href="mailto:info@frobeen.de?subject=Anfrage%20via%20galapagos.de" title="info@frobeen.de"
                    class="text-noline"><i class="fa fa-lg fa-envelope-o hellgrau"></i>
                    info@frobeen.de</a></p>
        </div>
        <a class="menuButton" (click)="renderMenu()" href="javascript:void()"><i class="fa fa-bars"></i></a>
    </div>
    <ul class="navbar-nav verticalMenu" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem" style="border-right: 1px solid #aaa;"><a
                href="https://www.galapagos.de/index.html" style="color: #9b2325;"><i class="fa fa-home fa-lg"></i></a>
        </li>
        <li class="nav-item menuItem" style="border-right: 1px solid #aaa;"><a
                href="https://www.galapagos.de/Galapagos-Kreuzfahrten/" target="_blank">Galapagos-Kreuzfahrten</a></li>
        <li class="nav-item menuItem" style="border-right: 1px solid #aaa;"><a
                href="https://www.galapagos.de/kontakt.php" target="_blank">Kontakt</a></li>
        <li class="nav-item menuItem"><a href="https://galapagos-pro.com/profis/" target="_blank">
                <a href="https://www.galapagos.de/index.html" target="_blank">
                    <img src="../../../../../../../../assets/images/de_round.svg" style="padding: 0 5px;"></a>
                <a href="https://www.galapagos.de/en/index.html" target="_blank">
                    <img src="../../../../../../../../assets/images/en_round.svg" style="padding: 0 5px;"></a>
                <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=fr&_x_tr_hl=de"
                    target="_blank">
                    <img src="../../../../../../../../assets/images/fr_round.svg" style="padding: 0 5px;"></a>
                <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=it&_x_tr_hl=de"
                    target="_blank">
                    <img src="../../../../../../../../assets/images/it_round.svg" style="padding: 0 5px;"></a>
                <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=es&_x_tr_hl=de"
                    target="_blank">
                    <img src="../../../../../../../../assets/images/es_round.svg" style="padding: 0 5px;"></a>
                <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=ru&_x_tr_hl=de"
                    target="_blank">
                    <img src="../../../../../../../../assets/images/ru_round.svg" style="padding: 0 5px;"></a>
                <a href="https://www-galapagos-de.translate.goog/index.html?_x_tr_sl=de&_x_tr_tl=zh-CN&_x_tr_hl=de"
                    target="_blank">
                    <img src="../../../../../../../../assets/images/cn_round.svg" style="padding: 0 5px;"></a>
            </a>
        </li>
        <li class="nav-item menuItem" *ngIf="companyParamsService.getFiltersInterest()">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </li>
    </ul>
</nav>
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Company,
  SocialMedia,
  HeaderActions,
  PaymentMethod,
  ListadoMenu,
  FilterSearch,
  FilterSearchValue
} from '../models/company';
import { PriceRateDescriptor } from '../enums/price-rate-descriptor.enum';
import { CatalogueFilters } from '../models/catalogue-filters';
import { Slogan, LisYearMonth, Interest } from '../models/company';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../shared/services/utils.service';
import { PriceRateService } from '../../modules/searcher/services/price-rate.service';
import { ScriptNamesEnum } from '../enums/script-names-enum';
import { PaypalConfiguration } from '../models/paypal-configuration';
import { PaypalMode } from '../enums/paypal/paypal-mode.enum';
import { isPlatformBrowser } from '@angular/common';
import { PaymentMethodTypeEnum } from '../enums/payment-methods-type.enum-';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyParamsService {
  company: Company | any;
  private dataDates: SearchParam[] = [];
  private arrMonths: number[] = [];
  selectedDepartureObj: any = {};
  private howFindUsList = [
    'Internet search',
    'Recommendation from friend / family',
    'Social media channels',
    'Other',
  ];
  currntMonth: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  keyCompany: string = '';
  dateToday = new Date();
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private priceRateService: PriceRateService,
    private localStorageService: LocalStorageService
  ) {  }
  setCompanyparams(company: Company, nameRequest: string) {
    this.company = company;
    this.company.nameRequest = nameRequest;
    this.setPriceAirFare(company);
  }

  setPriceAirFare(company: Company) {
    this.priceRateService.addPriceRate({
      codigo: 1,
      nombre: PriceRateDescriptor.AirFare,
      valor:
        !company.rateAirTicketAduld || company.rateAirTicketAduld === 0
          ? 500
          : company.rateAirTicketAduld,
    });
    this.priceRateService.addPriceRate({
      codigo: 2,
      nombre: PriceRateDescriptor.ChildrenAirFare,
      valor:
        !company.rateAirTicketChild || company.rateAirTicketChild === 0
          ? 325
          : company.rateAirTicketChild,
    });
    this.setKeyCompany(this.generateKeyCompany(company.nameRequest));
  }
  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = '0' + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = '0' + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }
  public generateKeyCompany(company: string): string {
    if (isPlatformBrowser(this.platformId)) {
      const encodeDate = window.btoa(this.getFormatedDateToday());
      const encodeCompany = window.btoa(encodeDate + ',' + company);
      return encodeCompany;
    } else {
      return '';
    }
  }

  setKeyCompany(key: string) {
    this.keyCompany = key;
  }
  getYears(): number[] {
    return this.getListYearMonth().map((item) => {
      return Number(item.code);
    });
  }

  getMonths(codeYear: number): string[] {
    let listMonth: string[] = [];
    const index = this.getListYearMonth().findIndex(
      (item) => Number(item.code) === Number(codeYear)
    );

    if (index >= 0) {
      listMonth = this.getListYearMonth()[index].description.split(',');
    }
    return listMonth;
  }
  setSercher(searcher: any) {
    if (this.company) this.company.searcher = searcher;
  }
  getOrigin() {
    return (
      this.company.searcher.empresa +
      '/' +
      this.company.searcher.barco +
      '/' +
      this.company.searcher.dias +
      '/' +
      this.company.searcher.fecha +
      '/' +
      this.company.searcher.pasajeros
    );
  }
  getCompanyParams(): Company | any {
    try {
      return this.company;
    } catch (error) {
      return '';
    }
  }
  getCode(): string {
    return this.company ? this.company.code : '';
  }
  getListYearMonth(): LisYearMonth[] {
    try {
      const today = new Date();

      const years = this.company.listYearMonth.filter(
        (item: any) => item.code >= today.getFullYear()
      );
      return years;
    } catch (error) {
      return [];
    }
  }
  getYearMonthByCode(code: number): LisYearMonth | undefined {
    try {
      let index = this.company.listYearMonth.findIndex(
        (item: any) => Number(item.code) === code
      );
      index = index < 0 ? 0 : index;
      return this.company.listYearMonth[index];
    } catch (error) {
      return undefined;
    }
  }
  fetchDates(auxYear: number, months: any[], selectedMonths: any[]) {
    this.selectedDepartureObj.year = auxYear;
    this.dataDates = [];
    months.forEach((valueMonth) => {
      if (valueMonth > 0) {
        this.dataDates.push({
          name: `${this.currntMonth[Number(valueMonth) - 1]}, ${auxYear}`,
          value: this.generateDateByStringYearAndMonthDate(
            `${valueMonth}, ${auxYear}`
          ).getTime(),
          selected: this.isSelected(valueMonth, selectedMonths, auxYear),
        });
      }
    });

    return this.dataDates;
  }

  isSelected(value: string, arraySelected: any[], auxYear: any): boolean {
    let index = arraySelected.findIndex((item) => String(item) === value);
    let today = new Date();

    return index >= 0 ||
      (Number(value) == today.getMonth() + 1 &&
        auxYear === today.getFullYear() &&
        arraySelected.length === 0)
      ? true
      : false;
  }

  public getDates(): SearchParam[] {
    try {
      const today = new Date();
      const auxString = today.getMonth() + 1 + ',' + today.getFullYear();
      const aux =
        this.generateDateByStringYearAndMonthDate(auxString).getTime();
      let resp: any = [];
      /*resp.push({
        name: "All months",
        value: 0,
        selected: true
      })*/
      this.dataDates.forEach((item) => {
        if (item.value >= aux) {
          resp.push(item);
        }
      });

      return resp;
    } catch (error) {
      return [];
    }
  }

  getAllMonthsArr(year: number): string[] {
    const today = new Date();
    const resp: string[] = [];
    this.arrMonths = [];
    for (let index = 0; index < 12; index++) {
      const element = new Date(year, index);
      this.arrMonths.push(element.getTime());
    }
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    this.arrMonths.forEach((item) => {
      if (item >= firstDay.getTime()) {
        resp.push(String(new Date(item).getMonth() + 1));
      }
    });

    return resp;
  }

  getAllMonths() {
    try {
      const today = new Date();
      const auxString = today.getMonth() + 1 + ',' + today.getFullYear();
      const aux =
        this.generateDateByStringYearAndMonthDate(auxString).getTime();
      let resp: any = [];

      this.dataDates.forEach((item) => {
        if (item.value >= aux) {
          resp.push(item);
        }
      });

      return resp;
    } catch (error) {
      return [];
    }
  }
  public generateDateByStringYearAndMonthDate(stringDate: string): Date {
    const dateArray = stringDate.split(',');
    if (dateArray.length >= 2) {
      const year = Number(dateArray[1]);
      const month = Number(dateArray[0]);
      if (
        Number.isSafeInteger(year) &&
        Number.isSafeInteger(month) &&
        month >= 1 &&
        month <= 12
      ) {
        return UtilsService.generateDateByYearAndMonth(year, month);
      }
    }
    return new Date();
  }

  getName(): string {
    try {
      return this.company ? this.company.name : '';
    } catch (error) {
      return '';
    }
  }
  getNameUrl(): string {
    try {
      return this.company.nameRequest;
    } catch (error) {
      return '';
    }
  }
  getBannerImg(): string {
    try {
      return this.company.bannerImg;
    } catch (error) {
      return '';
    }
  }
  getPhone(): string {
    try {
      return this.company.phone;
    } catch (error) {
      return '';
    }
  }
  getInfoEmail(): string {
    try {
      return this.company.infoEmail;
    } catch (error) {
      return '';
    }
  }
  geteEmail(): string {
    try {
      return this.company.emailEcommerce;
    } catch (error) {
      return '';
    }
  }
  getPrivacy(): string {
    try {
      return this.company.privacyUrl;
    } catch (error) {
      return '';
    }
  }
  getEmail(): string {
    try {
      return this.company.email;
    } catch (error) {
      return '';
    }
  }

  getAgencyCode(): string {
    try {
      return this.company.agency;
    } catch (error) {
      return '';
    }
  }

  getFooterColor(): string {
    try {
      return this.company.footerColor;
    } catch (error) {
      return '';
    }
  }
  getHeaderColor(): string {
    try {
      return this.company.headerColor;
    } catch (error) {
      return '';
    }
  }
  getMainColor(): string {
    try {
      return this.company.mainColor;
    } catch (error) {
      return '';
    }
  }
  getSecundaryColor(): string {
    try {
      return this.company.secondaryColor;
    } catch (error) {
      return '';
    }
  }
  getTertiaryColor(): string {
    try {
      return this.company.tertiaryColor;
    } catch (error) {
      return '';
    }
  }
  getLogo(): string {
    try {
      return environment.companyLogosImg + this.company.logo;
    } catch (error) {
      return '';
    }
  }
  getWebSite(): string {
    try {
      return this.company.webSite;
    } catch (error) {
      return '';
    }
  }
  getHasLogoInformation(): boolean {
    try {
      return this.company.hasLogoInformation;
    } catch (error) {
      return false;
    }
  }
  getWhatsappNumber(): string {
    try {
      return this.company.whatsappNumber;
    } catch (error) {
      return '';
    }
  }
  getFacebookLink(): string {
    try {
      return this.company.facebookLink;
    } catch (error) {
      return '';
    }
  }
  getTwitterLink(): string {
    try {
      return this.company.twitterLink;
    } catch (error) {
      return '';
    }
  }
  getGoogleLink(): string {
    try {
      return this.company.googleLink;
    } catch (error) {
      return '';
    }
  }
  getLinkedinLink(): string {
    try {
      return this.company.linkedinLink;
    } catch (error) {
      return '';
    }
  }
  getYoutubeLink(): string {
    try {
      return this.company.youtubeLink;
    } catch (error) {
      return '';
    }
  }

  getTripadvisorLink(): string {
    try {
      return this.company.tripadvisorLink;
    } catch (error) {
      return '';
    }
  }

  getInstagramLink(): string {
    try {
      return this.company.instagramLink;
    } catch (error) {
      return '';
    }
  }

  getSlogan(): Slogan {
    try {
      return this.company.slogan;
    } catch (error) {
      return { image: '', label: '', items: [], widthItem: '' };
    }
  }

  getSloganPrice(): string {
    try {
      return this.company.sloganPrice.label;
    } catch (error) {
      return '';
    }
  }

  getSloganImage(): string {
    try {
      return this.company.sloganPrice.image;
    } catch (error) {
      return '';
    }
  }
  getFaqLink(): string {
    try {
      return this.company.linkFaq;
    } catch (error) {
      return '';
    }
  }
  getSloganMenu(): string {
    try {
      return this.company.sloganMenu;
    } catch (error) {
      return '';
    }
  }

  getFadeMenu(): boolean {
    try {
      return this.company.fadeMenu;
    } catch (error) {
      return true;
    }
  }

  getPinterestLink(): string {
    try {
      return this.company.pinterestLink;
    } catch (error) {
      return '';
    }
  }
  getStatusEcommerce(): boolean {
    try {
      return this.company.statusEcommerce;
    } catch (error) {
      return false;
    }
  }
  getVisibleEcommerce(): boolean {
    try {
      return this.company.visibleEcommerce;
    } catch (error) {
      return false;
    }
  }
  getVisibleFilterSummary(): boolean {
    try {
      return this.company.visibleFilterSummary;
    } catch (error) {
      return false;
    }
  }
  getVisibleInquire(): boolean {
    try {
      return this.company.visibleInquire;
    } catch (error) {
      return false;
    }
  }
  getMenuList(): ListadoMenu[] {
    try {
      return this.company.listadoMenu;
    } catch (error) {
      return [];
    }
  }
  getListMenuOrdered(): ListadoMenu[] {
    return this.getMenuList().sort((n1, n2) => {
      if (n1.order && n2.order) {
        if (n1.order > n2.order) {
          return 1;
        }
        if (n1.order < n2.order) {
          return -1;
        }
        return 0;
      }
      return 0;
    });
  }
  hasEcommerce(): boolean {
    try {
      return this.company.visiblePago;
    } catch (error) {
      return false;
    }
  }
  getPercentPartialPayment(): number {
    try {
      return this.company.percentPartialPayment;
    } catch (error) {
      return 100;
    }
  }
  getPaymentMethods(): any[] {
    try {
      // return [
      //   {
      //     "code": 7,
      //     "type": 3,
      //     "name": "Pay with direct transfer",
      //     "icons": [],
      //     "description": "",
      //     "linkInfo": "",
      //     "paymentInformation": ""
      //   },
      //   {
      //     "code": 9,
      //     "type": 1,
      //     "name": "Check out with PayPal",
      //     "description": "The safer, easy way to pay",
      //     "linkInfo": "",
      //     "paymentInformation": "<br/>Thanks for your payment. <br/>We'll be in touch with you within 24 hours to confirm your reservation.<br/>",
      //     "icons": [],
      //     "objectConfiguration": {
      //       "apiMode": "sandbox",
      //       "clientProductionToken": "AXRHmFARCmMmKqcCM1r8fIWaF7N7wXVdYWwuCHVQDqz6zstE0BsgC4znWV2RGqdF86YlMFYjWhwBLMPG",
      //       "clientSandboxToken": "AXRHmFARCmMmKqcCM1r8fIWaF7N7wXVdYWwuCHVQDqz6zstE0BsgC4znWV2RGqdF86YlMFYjWhwBLMPG",
      //       "currency": "USD",
      //       "requestIntent": "authorize"
      //     }
      //   },
      //   {
      //     "code": 10,
      //     "type": 2,
      //     "name": "Pay with credit or debit card",
      //     "description": "",
      //     "linkInfo": "",
      //     "icons": [],
      //     "paymentInformation": ""
      //   }
      // ]
      return this.company.paymentMethods ? this.company.paymentMethods : [];
    } catch (error) {
      return [];
    }
  }
  getMinDaysPartialPayment(): number {
    try {
      return this.company.minDaysPartialPayment;
    } catch (error) {
      return 72;
    }
  }
  getListCategories(): CatalogueFilters[] {
    try {
      return !this.company.listCategories ? [] : this.company.listCategories;
    } catch (error) {
      return [];
    }
  }
  getListPromotions(): CatalogueFilters[] {
    try {
      return !this.company.listPromotions ? [] : this.company.listPromotions;
    } catch (error) {
      return [];
    }
  }
  getVisibleFilterPrices(): boolean {
    try {
      return this.company.visibleFilterPrices;
    } catch (error) {
      return false;
    }
  }
  getFooterMessage(): string {
    try {
      return this.company.footerMessage;
    } catch (error) {
      return '';
    }
  }

  getVisibleCharterOnly(): boolean {
    try {
      return this.company.visibleCharterOnly;
    } catch (error) {
      return true;
    }
  }

  getVisibleGroupsOnly(): boolean {
    try {
      return this.company.visibleGroupsOnly;
    } catch (error) {
      return true;
    }
  }

  getHeaderActions(): HeaderActions | undefined {
    try {
      return this.company.headerActions;
    } catch (error) {
      return undefined;
    }
  }
  getPosition(): string {
    try {
      return this.company.headerActions.position;
    } catch (error) {
      return '';
    }
  }

  getSocialMediaLis(): SocialMedia[] {
    try {
      return this.company.headerActions.socialMediaList;
    } catch (error) {
      return [];
    }
  }

  getOrientation(): string {
    try {
      return this.company.headerActions.orientation;
    } catch (error) {
      return '';
    }
  }

  getApplyTermsCondition(): boolean {
    try {
      return this.company.sloganPrice.visibleTermsCondition;
    } catch (error) {
      return false;
    }
  }

  getJsonGroupType(): boolean {
    try {
      return this.company.jsonGroupType;
    } catch (error) {
      return true;
    }
  }

  getHowFindUsList(): string[] {
    return this.howFindUsList;
  }

  getScripts(): Script[] {
    let scripts: ScriptJs[] = [];
    let results: any[] = [];
    scripts =
      this.getHeaderActions()?.scripts?.filter(
        (searchItem: any) =>
          searchItem.name === ScriptNamesEnum.FOR_INITIAL_PAGE
      ) || [];
    if (scripts) {
      scripts.forEach((item) => {
        if (this.validateLocalstorageData(item)) {
          results.push(item);
        }
      });
    }
    return results.map((item: any) => {
      return { srcLinks: item.url };
    });
  }

  validateLocalstorageData(item: any): boolean {
    const today = new Date();
    const timeInLocalStorage = this.localStorageService.getData(item.key);
    if (!timeInLocalStorage && item?.minTimeToEnable) {
      this.localStorageService.saveData(item?.key, today.getTime().toString());
      return true;
    } else {
      const msDifference = Number(today.getTime()) - Number(timeInLocalStorage);
      const hoursDifference = UtilsService.millisecondsToHours(msDifference);
      if (hoursDifference < item.minTimeToEnable) {
        return false;
      } else {
        this.localStorageService.removeData(item?.key);
        return true;
      }
    }
  }

  getScriptsForThankYouPage(): Script[] {
    let scripts: ScriptJs[] = [];
    scripts =
      this.getHeaderActions()?.scripts?.filter(
        (searchItem: any) =>
          searchItem.name === ScriptNamesEnum.FOR_THANK_YOU_PAGE
      ) || [];
    return scripts.map((item: any) => {
      return { srcLinks: item.url };
    });
  }

  getPayPalInfo(): PaypalConfiguration {
    if (this.getIndexPaypal() >= 0) {
      return this.getPaymentMethods()[this.getIndexPaypal()]
        .objectConfiguration;
    } else {
      throw 'THERE IS NOT PAYPAL OBJECT';
    }
  }

  getIndexPaypal() {
    return this.getPaymentMethods().findIndex(
      (item: any) => item.type == PaymentMethodTypeEnum.PAYPAL_CHECKOUT
    );
  }

  getIsRecommended(): boolean {
    try {
      return this.company.recommended;
    } catch (error) {
      return false;
    }
  }

  getTotalResults(): boolean {
    try {
      return this.company.headerActions.totalResults ? true : false;
    } catch (error) {
      return false;
    }
  }

  getVisiblePrices(): boolean {
    try {
      return this.company.visiblePrices;
    } catch {
      return true;
    }
  }

  isAirTicketsView(): boolean {
    if (this.company.airTicketsTitle === "" || this.company.airTicketsTitle === undefined) {
      return false;
    }
    return true;
  }

  getFiltersSearch(): FilterSearch[] | undefined {
    try {
      let filtersSearch : FilterSearch[] = [];
      const mapped = Object.entries(this.company.filtersSearch).map(([filterName, filterSearchValue]) => ({filterName, filterSearchValue}));
      mapped.forEach(entry =>{
        let fsv: FilterSearchValue = {name:'',status:''};
        let filterSearch: FilterSearch = {filterName:'',filterSearchValue:fsv};
        filterSearch.filterName = entry.filterName;
        filterSearch.filterSearchValue.name = (entry.filterSearchValue as FilterSearchValue).name;
        filterSearch.filterSearchValue.status = (entry.filterSearchValue as FilterSearchValue).status;
        filtersSearch.push(filterSearch);
      });
      return filtersSearch;
    } catch (error) {
      return undefined;
    }
  }

  getFiltersInterest():boolean{
    if(this.company.filtersSearch.interests === null || this.company.filtersSearch.interests === undefined){
        return  false
    }
    return  this.company.filtersSearch.interests.status;
  }


  getFiltersCompare():boolean {
    if (
        this.company.filtersSearch.compare === null ||
        this.company.filtersSearch.compare === undefined
    ) {
        return false;
    }
    return  this.company.filtersSearch.compare.status;
  }


  getFiltersSearchDate(): FilterSearch | undefined {
    try {
      return this.getFiltersSearch()?.find(f => f.filterName === 'date');
    } catch (error) {
      return undefined;
    }
  }

  getViewModes():ViewMode[] {
    try {
     let listViewModel : ViewMode[] = [];
     this.company.headerActions.viewModes.forEach((item: any) => {
            if( item === "by_date"){
              listViewModel.push({code:1,name:item,image:"assets/images/searcher/searchbydate.png", selected:false});
            }
            if( item === "by_boat"){
              listViewModel.push({code:2,name:item,image:"assets/images/searcher/searchbyboat.png", selected:false});
            }
            if( item === "by_departure"){
              listViewModel.push({code:3,name:item,image:"assets/images/searcher/searchbydeparture.png", selected:false});
            }
            if( item === "by_group"){
              listViewModel.push({code:4,name:item,image:"assets/images/searcher/searchbyrange.png", selected:false});
            }
     });
     if(listViewModel.length > 0){
        listViewModel[0].selected = true;
     }
      return listViewModel;
    } catch (error) {
      return [];
    }
  }
}

export interface ViewMode {
      code:number;
      name: string;
      image: string;
      selected:boolean;
    }
export interface SearchParam {
  name: string;
  value: any;
  selected?: boolean;
}
export interface ScriptJs {
  srcLinks: string[];
}
export interface Script {
  srcLinks: string;
}



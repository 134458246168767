export class Utils {

  static openNewTab(url: string): void {
    try {
      window.open(encodeURI(url), '_blank');
    } catch (error) {
      console.error(error);
    }
  }
}

export enum PaymentMethodTypeEnum {
  PAYPAL_CHECKOUT = 1,
  CREDIT_CARD_ONLINE = 2,
  GENERAL_TRANSFER = 3,
  STRIPE_CHECKOUT = 4,
  PAYPAL_OPTION_REQUEST = 5,
  FOREIGNS_BANKS = 6,
  LOCAL_TRANSFER = 7,
  SQUARE_CARD = 8,
}

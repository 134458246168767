export enum PriceRateDescriptor {
  AirFare,
  ChildrenAirFare,
  AirFare_C5_RACK_XOFF,
  AirFare_C4_RACK_XOFF,
  AirFare_C5_2X1,
  AirFare_C4_2X1,
  AirFare_C5_NET,
  AirFare_C4_NET,


  C5_RACK_XOFF,
  C4_RACK_XOFF,


  C5_2X1,
  C4_2X1,
  C5_NET,
  C4_NET,
  AirFare_C3C2_RACK_XOFF,
  AirFare_C3C2_2X1,
  AirFare_C3C2_NET,
  C3C2_RACK_XOFF,
  C3C2_2X1,
  C3C2_NET
}

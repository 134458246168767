import { Component, Injector } from '@angular/core';
import { Availability } from 'src/app/core/models/availability';
import { Favorite } from '../models/favorite';

@Component({
  selector: 'app-base',
  template: ` <p>base works!</p> `,
  styles: [],
})
export class BaseComponent {
  constructor(injector: Injector) {
  }

  setLocalStorageValue(keyValue: string, value: any) {
    localStorage.setItem(keyValue, JSON.stringify(value));
  }

  deleteLocalStorageValue(keyValue: string){
    localStorage.removeItem(keyValue);
  }

  getLocalStorageValue(keyValue: string): any {
    // Recupera la información del localStorage
    const storedData: string | null = localStorage.getItem(keyValue);
    const data: string | null = storedData ? JSON.parse(storedData) : null;
    return data !== null ? new Object(data) : null;
  }

  existsFavoriteInLocalStorage(agency:string,code:number, type:string): boolean {
    let jsonObject:Favorite[] = this.getLocalStorageValue('favorites'+agency);
    if(jsonObject === null){
      return false;
    }
    const indexOfObject = jsonObject.findIndex((object) => {
      return (Number(object.code) === Number(code) && object.type === type);
      });
      if(indexOfObject === -1){
        return false;
      }else{return true;}
  }

  existsLocalStorage(agency:string): boolean {
    let jsonObject:Favorite[] = this.getLocalStorageValue('favorites'+agency);
    if(jsonObject === null || jsonObject === undefined || jsonObject.length === 0){
      return false;
    }
    else{return true;}
  }

}

<nav #header class="fixed-top" style="background-color: white;">
    <div class="containerHeaderTop">
        <div>Unforgettable <span style="color: white;">Seasonal</span> Getaways</div>
    </div>
    <div class="containerMenu navbar-collapse collapse">
        <!---->
        <div>
            <a href="https://www.adventurejourneys.com/" target="_blank">
                <img class="logo" src="https://www.adventurejourneys.com/wp-content/uploads/2022/12/cropped-logo-AJ.webp" />
            </a>
        </div>
        <div>
            <img class="medal" src="../../../../../../../../assets/images/trip_adv.png" />
            <img class="medal" src="../../../../../../../../assets/images/wta.png" />
        </div>
        <div class="contactContainer">
            <ul class="navbar-nav contact">
                <li class="nav-item menuItem1">
                    <a href="https://www.youtube.com/@adventurejourneys2564/videos" target="_blank">
                        <i class="fa fa-youtube-play" style="color:red"></i>
                        <span>Youtube</span></a>
                </li>
                <li class="nav-item menuItem1">
                    <a href="https://www.adventurejourneys.com/tel:%20+001%20305%202805599" target="_blank">
                        <i class="fa fa-phone"></i>
                        <span>+001 305 2805599</span></a>
                </li>
                <li class="nav-item menuItem1">
                    <a href="mailto:info@adventurejourneys.com" target="_blank">
                        <i class="fa fa-envelope"></i>
                        <span>info@adventurejourneys.com</span></a>
                </li>
                <li class="nav-item menuItem1">
                    <a href="https://www.adventurejourneys.com/contact-us/" target="_blank">
                        <i class="fa fa-users"></i>
                        <span>Contact Us</span></a>
                </li>
                <li class="nav-item menuItem1">
                    <i class="fa fa-clock-o"></i>
                    <span>M-F 8am-9pm ET</span>
                </li>
            </ul>
        </div>
        <a class="menuButton" (click)="renderMenu()" href="javascript:void()"><i class="fa fa-bars"></i></a>
    </div>
    <div class="containerHeader" [ngClass]="showMenu ? 'showMenu':''">
        <ul class="navbar-nav">
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/destinations/"
                    target="_blank">DESTINATIONS</a></li>
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/travel-style/"
                    target="_blank">TRAVEL STYLE</a></li>
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/extension/"
                    target="_blank">TRIP EXTENSION</a></li>
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/about-us/" target="_blank">WHY US</a></li>
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/brochure/"
                    target="_blank">BROCHURE</a></li>
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/videos/" target="_blank">GET INSPIRED</a>
            </li>
            <li class="nav-item menuItem"><a href="https://www.adventurejourneys.com/responsible-tourism/"
                    target="_blank">SUSTAINABILITY</a>
            </li>
            <li class="nav-item menuItem"><a href="https://luxuryprivatejourneys.com/" target="_blank">LUXURY
                    PRIVATE JOURNEYS</a>

        </ul>
        <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
    </div>
</nav>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements OnInit {
  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  @Input() company!:string;
  headerHeightInput:any;
  constructor() { }

  ngOnInit(): void {
  }

  getHeight(height:any){
    this.headerHeightInput = height
  }
  ngAfterViewInit() {
    this.headerHeight.emit(this.headerHeightInput);
  }

}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, Observable, of, map } from 'rxjs';

import { CompanyRequestService } from './shared/services/company-request.service';
import { CompanyParamsService } from './core/services/company-params.service';
import { SortCruiseByService } from './shared/services/sort-cruise-by.service';
import { Location } from '@angular/common';
import { FavIconRequestService } from './shared/services/favicon-request.service';

@Injectable({
  providedIn: 'root',
})
export class AppResolver implements Resolve<any> {
  POSITION_COMPANY_NAME = 1;

  companyName = this.location.path().split('/')[this.POSITION_COMPANY_NAME];
  favIcon: any = document.querySelector('#appIcon');
  constructor(
    private companyRequestService: CompanyRequestService,
    private sortCruiseByService: SortCruiseByService,
    private companyParamsService: CompanyParamsService,
    private favIconRequestService: FavIconRequestService,
    private location: Location
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    this.favIconRequestService.setFavicons();

    return this.companyRequestService.requestServer().pipe(
      map((response) => {
        this.companyParamsService.setCompanyparams(response, this.companyName);
        this.sortCruiseByService.fetchWithParamasofCompany(
          this.companyParamsService.getCompanyParams().listFilters
        );
      }),
      catchError((error) => {
        return of('No data');
      })
    );
  }
}

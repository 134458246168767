import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
//import { GalavailServiceEcommer } from 'clientwsGalavail';
import { HttpRequestService } from './http-request.service';
import { Observable, of } from 'rxjs';
import { Location, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CompanyRequestService {
  private requestCompany: any;

  public dateToday: Date = new Date();
  public POSITION_COMPANY_NAME = 1;
  public companyName = this.location.path().split('/')[
    this.POSITION_COMPANY_NAME
  ];
  public totalCruises: number = 0;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private httpRequestService: HttpRequestService,
    private location: Location
  ) {}

  requestServer(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {

      return this.httpRequestService.requestPost(
        'findCompany/',
        this.generateKeyCompany()
      );
    }else{
      return of(undefined)
    }
  }

  public generateKeyCompany(): any {
    if (isPlatformBrowser(this.platformId)) {
      const encodeDate = window.btoa(this.getFormatedDateToday());
      const encodeCompany = window.btoa(encodeDate + ',' + this.companyName);
      this.requestCompany = {
        key: encodeCompany,
      };
      return this.requestCompany;
    } else {
      return '';
    }
  }

  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = '0' + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = '0' + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }
}

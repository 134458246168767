import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
//import { GalavailServiceEcommer } from 'clientwsGalavail';
import { isPlatformBrowser, Location } from '@angular/common';
import { Observable, of, map, catchError } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { FavIconModel } from '../../core/models/favicon-model.ts';

@Injectable({
  providedIn: 'root',
})
export class FavIconRequestService {
  private requestCompany: any;
  favIcons: FavIconModel[] = [
    {
      name: 'happygringo',
    },
  ];
  favIcon: any = document.querySelector('#appIcon');
  public dateToday: Date = new Date();
  public POSITION_COMPANY_NAME = 1;
  public companyName = this.location.path().split('/')[
    this.POSITION_COMPANY_NAME
  ];

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
    private httpRequestService: HttpRequestService,
    private location: Location
  ) {}

  requestServer(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpRequestService.requestPost(
        'findFavIcons/',
        this.companyName
      );
    } else {
      return of(undefined);
    }
  }

  setFavicons() {
    this.httpRequestService
      .requestPost('findFavIcons/', { key: this.companyName })
      .subscribe(
        (response) => {
          this.favIcons = response;
          this.favIcon.href = 'https://agencies.galavail.com/favicon.ico'
          if (response.pathIcon) this.favIcon.href = response.pathIcon;
        },
        (error) => {
          this.favIcon.href = 'https://agencies.galavail.com/favicon.ico'
        }
      );
  }
}

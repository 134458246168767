<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}" style="background-color: #fff;">
    <div class="contactHeader">
        <div class="container green containerHeader">
            <div>
                <a style="text-decoration: none;margin-right: 10px;">
                    <i class="fa fa-phone" style="padding: 0 10px; color:#e32b5a ;"></i>
                    +593 (06) 2554243 </a>
                <a style="text-decoration: none;margin: 0 10px;">
                    <i class="fa fa-envelope-o" style="padding: 0 10px; color:#FFBD00"></i>
                    quichuatours@gmail.com</a>
                <div class="sn-desktop">
                    <a style="text-decoration: none;margin: 0 10px;"
                        href="https://www.tripadvisor.es/Attraction_Review-g303848-d13201682-Reviews-Quichua_Native_Travel-Otavalo_Imbabura_Province.html"
                        target="_blank">
                        <i class="fa fa-tripadvisor fa-border" style="font-size: 1.33em;"></i></a>
                    <a style="text-decoration: none;margin: 0 10px;" href="https://forms.gle/mRjyWvPAMMqKJbad9"
                        target="_blank">
                        <i class="fa fa-address-book fa-border pasantias" style="font-size: 1.33em;"></i></a>
                    <a href="https://www.instagram.com/quichuatravelecuador/"><i class="fa fa-instagram"
                            style="padding: 0 5px;"></i></a>
                    <a href="https://www.facebook.com/quichuatravelecuador"><i class="fa fa-facebook"
                            style="padding: 0 5px;"></i></a>
                    <a href="https://www.youtube.com/channel/UCkB0ueIEn6E0IkHoOnCpmPg"><i class="fa fa-youtube"
                            style="padding: 0 5px;"></i></a>
                    <a href="https://twitter.com/quichua_tours"><i class="fa fa-twitter"
                            style="padding: 0 5px;"></i></a>
                </div>

            </div>
            <div class="sn-responsive">
                <a style="text-decoration: none;margin: 0 10px;"
                    href="https://www.tripadvisor.es/Attraction_Review-g303848-d13201682-Reviews-Quichua_Native_Travel-Otavalo_Imbabura_Province.html"
                    target="_blank">
                    <i class="fa fa-tripadvisor fa-border" style="font-size: 1.33em;"></i></a>
                <a style="text-decoration: none;margin: 0 10px;" href="https://forms.gle/mRjyWvPAMMqKJbad9"
                    target="_blank">
                    <i class="fa fa-address-book fa-border pasantias" style="font-size: 1.33em;"></i></a>
                <a href="https://www.instagram.com/quichuatravelecuador/"><i class="fa fa-instagram"
                        style="padding: 0 5px;"></i></a>
                <a href="https://www.facebook.com/quichuatravelecuador"><i class="fa fa-facebook"
                        style="padding: 0 5px;"></i></a>
                <a href="https://www.youtube.com/channel/UCkB0ueIEn6E0IkHoOnCpmPg"><i class="fa fa-youtube"
                        style="padding: 0 5px;"></i></a>
                <a href="https://twitter.com/quichua_tours"><i class="fa fa-twitter" style="padding: 0 5px;"></i></a>
            </div>
            <div>
                <a href="https://www.quichuatours.com/" style="font-size: 0.87em;"><i class="fa fa-lock"
                        style="padding: 0 10px;"></i> Login</a>
                <a href="https://www.quichuatours.com/" style="font-size: 0.87em;"><i class="fa fa-user"
                        style="padding: 0 10px;"></i> Sign up</a>
            </div>
        </div>
    </div>

    <div #header1 class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://www.quichuatours.com/" class="navbar-brand"
            target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <img src="https://www.quichuatours.com/wp-content/uploads/2020/06/LOGOTIPO-OFICAL-KICHWAEXPEDITION-766X349.png"
                alt="logo" class="brand-logo" style="height: 59px;">
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://www.quichuatours.com/" target="_blank">WHO WE ARE</a>
                </li>
                <li class="nav-item menuItem"><a
                        href="https://www.quichuatours.com/community-tourism-development-ecuador/"
                        target="_blank">CULTURAL TOURS</a></li>
                <li class="nav-item menuItem"><a
                        href="https://www.quichuatours.com/search-tours-the-best-in-one-option/"
                        target="_blank">TOURS</a></li>
                <li class="nav-item menuItem"><a href="https://www.quichuatours.com/community-accommodation-cotacachi/"
                        target="_blank">ACCOMODATION</a></li>
                <li class="nav-item menuItem"><a
                        href="https://www.quichuatours.com/contact-us-tours-and-travels-ecuador/"
                        target="_blank">CONTACT</a></li>
                <li class="nav-item menuItem"><a href="https://www.quichuatours.com/" target="_blank"><i
                            class="fa fa-search" style="color:#e32b5a ;"></i></a></li>

            </ul>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>
        <div class="container green containerHeader" style="justify-content: end; flex-direction: row!important;"
            class="responsiveHeader">
            <a href="https://www.quichuatours.com/" target="_blank" style="padding-right: 1em;"><i class="fa fa-search"
                    style="color:#e32b5a ;"></i></a>
            <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="padding-right: 1em;">
                <i class="fa fa-bars fa-border" style="color:#e32b5a ;"></i>
            </a>
        </div>

    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://www.quichuatours.com/" target="_blank">WHO WE ARE</a></li>
        <li class="nav-item menuItem"><a href="https://www.quichuatours.com/community-tourism-development-ecuador/"
                target="_blank">CULTURAL TOURS</a></li>
        <li class="nav-item menuItem"><a href="https://www.quichuatours.com/search-tours-the-best-in-one-option/"
                target="_blank">TOURS</a></li>
        <li class="nav-item menuItem"><a href="https://www.quichuatours.com/community-accommodation-cotacachi/"
                target="_blank">ACCOMODATION</a></li>
        <li class="nav-item menuItem"><a href="https://www.quichuatours.com/contact-us-tours-and-travels-ecuador/"
                target="_blank">CONTACT</a></li>
    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>
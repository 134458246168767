import { Injectable } from '@angular/core';
import { SortCruiseByEnum } from '../../core/enums/sort-cruise-by.enum';
import { SortCruiseBy } from '../../core/models/sort-cruise-by.model';
import { CatalogueFilters } from '../../core/models/catalogue-filters';

@Injectable({
  providedIn: 'root',
})
export class SortCruiseByService {
  private sortCruiseByItems: SortCruiseBy[] = [];

  constructor() {
    this.setSortCruiseByItems([
      {
        name: 'Category: 5 to 2 stars',
        value: SortCruiseByEnum.CATEGORY_DESC,
        property: 'categoria',
        operator: '<',
      },
      {
        name: 'Category: 2 to 5 stars',
        value: SortCruiseByEnum.CATEGORY_ASC,
        property: 'categoria',
        operator: '>',
      },
      {
        name: 'Price (High to Low)',
        value: SortCruiseByEnum.PRICE_HIGH_TO_LOW,
        property: 'precio',
        operator: '<',
      },
      {
        name: 'Price (Low to High)',
        value: SortCruiseByEnum.PRICE_LOW_TO_HIGH,
        property: 'precio',
        operator: '>',
      },
    ]);
  }

  private setSortCruiseByItems(sortCruiseByItems: SortCruiseBy[]): void {
    try {
      this.sortCruiseByItems = sortCruiseByItems;
    } catch (error) {
      console.log('SortCruiseByMService.setSortCruiseByItems()');
      console.error(error);
    }
  }

  public getSortCruiseByItems(): SortCruiseBy[] {
    try {
      return this.sortCruiseByItems;
    } catch (error) {
      console.log('SortCruiseByMService.getSortCruiseByItems()');
      console.error(error);
      return [];
    }
  }

  fetchWithParamasofCompany(newList: CatalogueFilters[]) {
    if (!this.isNullOrUndefined(newList) && newList.length > 0) {
      this.sortCruiseByItems = [];
      newList.forEach((item) => {
        if (item.status) {
          const toAddvalue = {
            name: item.label,
            value: Number(item.value),
          };
          this.sortCruiseByItems.push(toAddvalue);
        }
      });
    }
  }

  public getSortCruiseItemByValue(value: number): SortCruiseBy | null {
    try {
      let result: any = null;
      this.getSortCruiseByItems().forEach((sortCruiseByitem: SortCruiseBy) => {
        if (sortCruiseByitem.value === value) {
          result = sortCruiseByitem;
        }
      });
      return result;
    } catch (error) {
      console.log('SorCruiseByService.getSortCruiseItemByValue()');
      console.error(error);
      return null;
    }
  }

  isNullOrUndefined(value: any) {
    return value === null || value === undefined;
  }
}

import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { CompanyParamsService } from 'src/app/core/services/company-params.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-galapagosde',
  templateUrl: './galapagosde.component.html',
  styleUrls: ['./galapagosde.component.scss']
})
export class GalapagosdeComponent implements OnInit {

  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  @ViewChild('header') headerRef!: ElementRef;

  constructor(
    public companyParamsService: CompanyParamsService,
    public router: Router
  ) { }
  showMenu: boolean = false;

  ngOnInit(): void {
  }

  renderMenu() {
    this.showMenu = !this.showMenu;
  }

  goFavorites() {
    const urlFavorites: any =
      this.companyParamsService.getNameUrl() +
      '/wishlist';
    Utils.openNewTab(urlFavorites);
  }

  ngAfterViewInit() {
    var height = this.headerRef.nativeElement.offsetHeight;
    this.headerHeight.emit(height);
  }

  link() {
    this.router.navigate(['/' + this.companyParamsService.getNameUrl() + '/search/2/0/2,3,4,5,6,7,8,9,10,11,12/2025/0/10000/0/0/0/0/1/0/0'])
      .then(() => {
        window.location.reload();
      });
  }

}

import { PayFlowTransactionTypeEnum } from '../app/core/enums/payflow/payflow-transaction-type.enum';
import { SingleCabinFormCapacityType } from '../app/core/enums/single-cabin-form-capacity-type.enum';
import { PayflowRequestVerbosityEnum } from '../app/core/enums/payflow/payflow-request-verbosity.enum';
import { PaypalMode } from '../app/core/enums/paypal/paypal-mode.enum';
import { PaypalRequestIntent } from '../app/core/enums/payflow/paypal-request-intent.enum';
import { NumberTypeEnum } from '../app/core/enums/payflow/number-type.enum';
export const environment = {
  production: false,
  console_log: true,

  GI_ECOM_CONTACT_FORM:
    'https://rest.galavail.com/gi-web/webresources/giforms/ecom/',
  geoIpServiceUrl: 'https://geoip.maxmind.com/geoip/v2.1/city/me',
  pathUrlOrigin: 'https://pruebas-ecommerce.galavail.com/',
  app_resources: {
    images: { path: 'assets/images/' },
    icons: { path: 'assets/images/icons/' },
    fleet_photo_directory: {
      server: 'https://galavail.com',
      path: 'upload/fleet',
    },
    cruise_photo_directory: {
      server: 'https://galavail.com',
      path: 'upload/cruises',
    },
    cabin_photo_directory: {
      server: 'https://galavail.com',
      path: 'upload/cabinas',
    },
    itinerary_photo_directory: {
      server: 'https://galavail.com',
      path: 'upload/itinerario',
    },
    fake_cruise_default_photo: { path: 'assets/images/icons/loading_icon.gif' },
  },
  restCountriesServer: 'https://restcountries.eu/rest/v2/all',
  single_cabin_form_capacity: {
    type: SingleCabinFormCapacityType.FIXED,
    capacity: 2,
  },
  firebase: {
    apiKey: 'AIzaSyAGqNllLBVRBFGVUzB3H-7-xTHKq26XPls',
    authDomain: 'e-commerce-desarrollo.firebaseapp.com',
    databaseURL: 'https://e-commerce-desarrollo.firebaseio.com',
    projectId: 'e-commerce-desarrollo',
    storageBucket: 'e-commerce-desarrollo.appspot.com',
    messagingSenderId: '119233533832',
  },
  ngGtcPayflow: {
    apiUrl:
      'https://develop.api.galavail.com/webservice/agencyServices/payflowNew/',
    numberType: NumberTypeEnum.ODD,
    defaultVerbosity: PayflowRequestVerbosityEnum.high,
    defaultTransactionType: PayFlowTransactionTypeEnum.sale,
    clientTimeOutForRequestingTransactions: 45,
  },
  ngGtcPayflowonHold: {
    apiUrl:
      'https://develop.api.galavail.com/webservice/agencyServices/payflowNew/',
    numberType: NumberTypeEnum.ODD,
    defaultVerbosity: PayflowRequestVerbosityEnum.high,
    defaultTransactionType: PayFlowTransactionTypeEnum.sale,
    clientTimeOutForRequestingTransactions: 45,
  },
  firebase_table_names: {
    genders: 'genders',
    countries: 'countries',
    purchase_requests: 'purchase_requests',
    itineraries_periods: 'itineraries_periods',
    ip_white_list: 'ipwhitelist',
  },
  BOOKING_CONFIRMATION_SERVICE:
    'https://develop.api.galavail.com/webservice/agencyServices/sendInquireBookingMail',
  PAYPAL_EXPRESS_CHECKOUT_CONFIG: {
    API_MODE: PaypalMode.SANDBOX,
    REQUEST_INTENT: PaypalRequestIntent.CAPTURE,
    CLIENT_SANDBOX_TOKEN:
      'AXRHmFARCmMmKqcCM1r8fIWaF7N7wXVdYWwuCHVQDqz6zstE0BsgC4znWV2RGqdF86YlMFYjWhwBLMPG',
    CLIENT_PRODUCTION_TOKEN:
      'AXRHmFARCmMmKqcCM1r8fIWaF7N7wXVdYWwuCHVQDqz6zstE0BsgC4znWV2RGqdF86YlMFYjWhwBLMPG',
    CURRENCY: 'USD',
  },
  PURCHASE: {
    MIN_TIME_TO_ENABLE_AUTOPAYMENT: 72,
    IS_AUTO_PAYMENT_ACCEPTED: true,
    search_expiration_time: 1000,
  },
  pathEcommerceGalavail: 'https://pruebas-ecommerce.galavail.com',
  itineraryPathImg: 'https://develop.galavail.com/upload/itinerario/',
  fleetPathImg: 'https://develop.galavail.com/upload/fleet/',
  fleetPathImgCard: 'https://develop.galavail.com/upload/fleet/card/',
  companyLogosImg: 'https://develop.galavail.com/upload/company/logos/',
  cabinPathImg: 'https://develop.galavail.com/upload/cabinas/',
  cruisePhotosPathImg: 'https://develop.galavail.com/upload/cruises/',
  itineraryPhotoDirectory: 'https://develop.galavail.com/upload/itinerario/',
  deckplans: 'https://develop.galavail.com/upload/deckplans',
  visitorPoint: 'https://galavail.com/upload/visitas/',
  pathService: 'https://develop.api.galavail.com/webservice/agencyServices',
  pathServiceAv: 'https://develop.availability.api.galavail.com/webservice/agencyServices',
  ngGtcPayStripe: {
    apiUrl:
      'https://develop.api.galavail.com/webservice/agencyServices/paystripe/',
    numberType: NumberTypeEnum.ODD,
    defaultVerbosity: PayflowRequestVerbosityEnum.high,
    defaultTransactionType: PayFlowTransactionTypeEnum.sale,
    clientTimeOutForRequestingTransactions: 45,
  },
  ngGtcPaySquare: {
    apiSquare:'https://sandbox.web.squarecdn.com/v1/square.js',
    apiUrl:"https://develop.api.galavail.com/webservice/agencyServices/paysquare/",
    numberType: NumberTypeEnum.ODD,
  },
  pathAgencyEcommerceAPI:{
    findLastMinute: 'https://develop.api.galavail.com/webservice/agencyEcommerceAPI/findLastMinute',
  }
};

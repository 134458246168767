<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div style="background-color: #76A234;" class="contactHeader">
        <div class="container green containerHeader">

            <div>
                <a style="text-decoration: none; padding-right: 10px;"
                    href="https://www.quitobiketours.com/tel:+593988529827" target="_blank">
                    <i class="fa fa-phone" style="padding: 0 5px;"></i>
                    +593 98 852 9827</a>
                <a href="mailto:nfo@quitobiketours.com" target="_blank" style="padding-right: 10px;">
                    <i class="fa fa-envelope" style="padding: 0 5px;"></i>
                    info@quitobiketours.com</a>
                <a href="https://g.page/QuitoBikeTours?share" target="_blank" style="padding-right: 10px;">
                    <i class="fa fa-map-marker" style="padding: 0 5px;"></i>
                    Head Office Quito – Ecuador</a>
            </div>
            <div style="font-size: 18px;">
                <a href="https://www.facebook.com/quitobiketours"><i class="fa fa-facebook"
                        style="padding: 0 5px;"></i></a>
                <a href="https://www.instagram.com/quitobiketours/"><i class="fa fa-instagram"
                        style="padding: 0 5px;"></i></a>
                <a
                    href="https://www.tripadvisor.com.au/Attraction_Review-g294308-d14984259-Reviews-Quito_Bike_Tours-Quito_Pichincha_Province.html"><i
                        class="fa fa-tripadvisor" style="padding: 0 5px;"></i></a>
                <a href="https://g.page/QuitoBikeTours?share"><i class="fa fa-google" style="padding: 0 5px;"></i></a>
            </div>
        </div>
    </div>

    <div #header1 class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://www.quitobiketours.com/" class="navbar-brand"
            target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <img src="https://www.quitobiketours.com/wp-content/uploads/quito-bike-tour-largo-copia.webp" alt="logo"
                class="brand-logo logo">
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit; padding: 20px;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/" target="_blank">Home</a></li>
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/bike-tours-in-quito-ecuador"
                        target="_blank">Bike Tours</a></li>
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/"
                        target="_blank">Sightseeing Tours</a></li>
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/galapagos-cruises-tours/"
                        target="_blank">Galapagos Cruises</a></li>
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/galapagos-island-hopping-tours-from-quito-ecuador/" target="_blank">Galapagos
                        Islands Hopping</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/?post_type=themo_tour&p=13132&preview=true"
                        target="_blank">Jungle Tours</a></li>
                <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/about-us/"
                        target="_blank">About Us</a></li>
            </ul>
        </div>
        <div style="display: flex;align-items: center;">
            <a href="https://www.quitobiketours.com/contact/" class="rightButton" target="_blank">Get in touch <i
                    class="fa fa-long-arrow-right"></i></a>
        </div>
        <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart-o"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
        <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="padding-right: 1em;">
            <i class="fa fa-bars fa-2x" style="display: flex;align-items: center;"></i>
        </a>
    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/" target="_blank">Home</a></li>
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/bike-tours-in-quito-ecuador"
                target="_blank">Bike Tours</a></li>
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/"
                target="_blank">Sightseeing Tours</a></li>
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/galapagos-cruises-tours/"
                target="_blank">Galapagos Cruises</a></li>
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/tour/galapagos-island-hopping-tours-from-quito-ecuador/" target="_blank">Galapagos
                Islands Hopping</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/?post_type=themo_tour&p=13132&preview=true"
                target="_blank">Jungle Tours</a></li>
        <li class="nav-item menuItem"><a href="https://www.quitobiketours.com/about-us/"
                target="_blank">About Us</a></li>
    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  numberMaxArray = 10;
  arrayLoading: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.generateArray();
  }

  generateArray() {
    for (let index = 0; index < this.numberMaxArray; index++) {
      this.arrayLoading.push(index);
    }
  }
}

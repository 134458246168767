<nav #header class="fixed-top" style="background-color: white;">
    <div class="containerHeader">
        <div class="kontakt">
            Kontakt:
            <a href="mailto:info@galapagos-pro.com" class="leftMailto">info@galapagos-pro.com</a>
        </div>
        <div style="width: 300px;">
            <a href="{{this.companyParamsService.getWebSite()}}" target="_blank"><img src="https://galapagos-pro.com/wp-content/uploads/2024/01/GalapagosPRO-Logo.svg" class=""
                    alt="" style="max-width: 320px; height: auto;" decoding="async"></a>
        </div>
        <div class="entdeckt"><a href="https://galapagos-pro.com/ecuador/" class="rightButton" target="_blank">Entdeckt ganz Ecuador</a></div>
        <a class="menuButton" (click)="renderMenu()" href="javascript:void()" ><i class="fa fa-bars"></i></a>
    </div>
    <div class="containerMenu navbar-collapse collapse" [ngClass]="showMenu ? 'showMenu':''">
        <ul class="navbar-nav">
            <li class="nav-item menuItem"><a href="https://galapagos-pro.com/galapagos-inseln/" target="_blank"><b>Über
                        Galapagos</b></a></li>
            <li class="nav-item menuItem"><a
                    href="https://galapagos-pro.com/galapagos-kreuzfahrten/" target="_blank">Galapagos-Kreuzfahrten</a></li>
            <li class="nav-item menuItem"><a
                    href="https://galapagos-pro.com/galapagos-inselhopping/" target="_blank">Galapagos-Reisen</a></li>
            <li class="nav-item menuItem"><a href="https://galapagos-pro.com/profis/" target="_blank">Profis</a></li>
            <li class="nav-item menuItem"><a href="https://galapagos-pro.com/servicegarantie/" target="_blank">Reise-Infos</a></li>
            <li class="nav-item menuItem"><a href="https://shop.galapagos-pro.com/" target="_blank">Shop</a></li>
            <li class="nav-item menuItem"><a href="https://galapagos-pro.com/reiseberatung/" target="_blank">Kontakto</a></li>
        </ul>
        <div class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
    </div>
</nav>
<div class="row" *ngFor="let item of arrayLoading" style="padding-bottom: 20px;">
    <div class="col-12">
        <div class="cruise-list-card" style="margin-bottom: 0px">
            <div class="promo-esq preload">
            </div>
            <div class="row no-gutters">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="cruise-img-box">
                        <img src="assets/images/cruises/loading-boat.gif" alt="img alt">


                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12">
                    <div class="p-3">
                        <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-12 ">

                                <div class="loading-row">
                                    <div class="text w-70 loading-animate" style="height: 36px;"></div>
                                </div>
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 16px;"></div>
                                </div>
                                <div class="cruise-location">
                                    <div class="loading-row">

                                        <div class="text w-100 loading-animate" style="margin-bottom: 0px;height: 13px;"></div>
                                        <div class="text w-100 loading-animate" style="margin-bottom: 0px;height: 13px;"></div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <div class="small " style="display: flex;">

                                        <div class="loading-row">
                                            <div class="text loading-animate" style="height: 30px; margin-right: 5px; width: 81px"></div>
                                        </div>
                                        <div class="loading-row" style="display: flex;">
                                            <div class="text w-100 loading-animate" style="height: 13px;    margin-top: 19px;
                                            "></div>
                                        </div>
                                    </div>
                                    <div class="loading-row">

                                        <div class="text w-100 loading-animate" style="height: 15px;"></div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- salidas -->
            <div class="depart no-more-tables bg-light">
                <table class="w-100 table my-0">
                    <thead class="bg-secondary text-white">
                        <tr>
                            <th scope="col">Departure</th>
                            <th scope="col">Availability</th>
                            <th scope="col">Itinerary</th>
                            <th scope="col">From</th>
                            <th scope="col">Offers</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>



                        <tr>
                            <td data-title="Departure:"><strong class="text-secondary">
                        <div class="loading-row">
                            <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                        </div>
                    </strong></td>
                            <td data-title="Availability:">
                                <em>   <div class="loading-row">
                            <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                        </div></em>
                            </td>
                            <td data-title="Itinerary:">
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>
                            </td>
                            <td data-title="Prices from:">

                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>
                            </td>
                            <td data-title="Offers:" class="promos">
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>

                            </td>

                            <td data-title="Actions:" style="width: 15%;" class="text-left text-lg-right text-nowrap">
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>
                            </td>

                        </tr>

                        <tr>
                            <td data-title="Departure:"><strong class="text-secondary">
                        <div class="loading-row">
                            <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                        </div>
                    </strong></td>
                            <td data-title="Availability:">
                                <em>   <div class="loading-row">
                            <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                        </div></em>
                            </td>
                            <td data-title="Itinerary:">
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>
                            </td>
                            <td data-title="Prices from:">

                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>
                            </td>
                            <td data-title="Offers:" class="promos">
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>

                            </td>

                            <td data-title="Actions:" style="width: 15%;" class="text-left text-lg-right text-nowrap">
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                                </div>
                            </td>

                        </tr>



                    </tbody>
                </table>
            </div>
            <!-- /salidas -->
        </div>

    </div>


    <br>

</div>
<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}" style='background-color: #fff;'>
    <div #header1 class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="https://www.galapagoslastminutes.com/content/themes/glm/img/general/glm_logo.png" height="50"
                alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://www.galapagoslastminutes.com/" class="navbar-brand"
            target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <span class="custom-logo" width="180" height="50"></span>
        </a>
        <div class="containerMenu navbar-collapse collapse" style="flex-basis: 0%;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/lastminutes/" target="_blank">Lastminutes</a></li>
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/cruises" target="_blank">Cruises</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/land-based-tours/" target="_blank">Land Tours</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/jungle-tours/"
                        target="_blank">Jungle Tours</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/galapagos-infos/" target="_blank">Galapagos
                        infos</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/blog/"
                        target="_blank">Blog</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/faqs/"
                        target="_blank">FAQs</a>
                </li>
            </ul>
        </div>
        <div class="button-container">
            <div>
                <a href="https://www.galapagoslastminutes.com/contact/" target="_blank">Contact <i class="fa fa-bell-o"></i> </a>
                &nbsp;|&nbsp;
                <a href="https://www.galapagoslastminutes.com/" target="_blank"><img class="flag"
                        src="../../../../../../../../assets/images/en-flag.svg" alt="English" height="12"><span
                        class="responsive-span"> English</span></a>
            </div>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}"
                    target="_blank">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>


    </div>
    <div class="responsiveHeader">
        <div class="container green containerHeader"
            style="display:flex; flex-direction: row!important;">
            <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="padding:0 20px;">
                <i class="fa fa-bars fa-2x"></i>
            </a>
            <img src="https://www.galapagoslastminutes.com/content/themes/glm/img/general/glm_logo.png"
                width="150" />
        </div>
    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/lastminutes/" target="_blank">Lastminutes</a></li>
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/cruises" target="_blank">Cruises</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/land-based-tours/" target="_blank">Land Tours</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/jungle-tours/"
                target="_blank">Jungle Tours</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/galapagos-infos/" target="_blank">Galapagos
                infos</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/blog/"
                target="_blank">Blog</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.galapagoslastminutes.com/faqs/"
                target="_blank">FAQs</a>
        </li>
    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-grouped',
  templateUrl: './loading-grouped.component.html',
  styleUrls: ['./loading-grouped.component.scss']
})
export class LoadingGroupedComponent implements OnInit {

  numberMaxArray = 10;
  arrayLoading: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.generateArray();
  }

  generateArray() {
    for (let index = 0; index < this.numberMaxArray; index++) {
      this.arrayLoading.push(index);
    }
  }

}

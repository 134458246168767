import { Component, Injector, OnInit, EventEmitter, Output } from '@angular/core';
import { TypeListMenu } from '../../enums/type-list-menu';
import { CompanyParamsService } from '../../services/company-params.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/utils';
import { BaseComponent } from 'src/app/shared/base/base.component';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'is-what';
import { document } from 'ngx-bootstrap';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  typeListMenu = TypeListMenu;
  renderMenu: boolean = false;
  company?: any;
  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  @Output() public headerHeight1: EventEmitter<any> = new EventEmitter();
  headerHeightInput: any;
  headerHeightInput1: any;
  mainColor: any;
  isDev: boolean = false;

  constructor(
    public companyParamsService: CompanyParamsService,
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.company = this.companyParamsService.company.headerActions.headerTemplate;
    this.renderMenu = isNullOrUndefined(this.company);
    this.mainColor = this.companyParamsService.getMainColor();
    this.isDev = environment.production ? false : true;
  }

  goFavorites() {
    const urlFavorites: any =
      this.companyParamsService.getNameUrl() +
      '/wishlist';
    Utils.openNewTab(urlFavorites);
  }

  getHeight(height: any) {
    this.headerHeightInput = height;
  }

  getHeight1(height: any) {
    this.headerHeightInput1 = height;
  }

  ngAfterViewInit() {
    this.headerHeightInput = isNullOrUndefined(this.headerHeightInput) ? document.getElementById("nvbar").offsetHeight : this.headerHeightInput;
    this.headerHeight.emit(this.headerHeightInput);
    this.headerHeightInput1 = isNullOrUndefined(this.headerHeightInput1) ? document.getElementById("nvbar").offsetHeight : this.headerHeightInput1;
    this.headerHeight1.emit(this.headerHeightInput1);
  }

  setCustomCSS(css: string): any {
    let q = css != undefined ? JSON.parse(css.replace("'", "").replace("'", "").trim()) : "";
    return q;
  }


}

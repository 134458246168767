<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div style="background-color: #000;" class="contactHeader">
        <div class="container green containerHeader">
            <div><a href="https://www.imagineecuador.com/"><img class="flag"
                        src="../../../../../../../../assets/images/en-flag.svg" alt="English" height="12"> English</a> /
                <a href="https://www.imagineecuador.com/es/"><img class="flag"
                        src="../../../../../../../../assets/images/es.svg" alt="Spanish"> Spanish</a>
            </div>
        </div>
    </div>

    <div #header1 class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://www.imagineecuador.com/" class="navbar-brand"
            target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <span class="custom-logo" width="180" height="50"></span>
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit;">
            <ul class="navbar-nav menuContainer" style="width: inherit;height: inherit;background-color: #00000066;">
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/" target="_blank">HOME</a></li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/galapagos-tours-cruises/"
                        target="_blank">GALAPAGOS</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/amazon-tours/"
                        target="_blank">AMAZON TOURS</a></li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/whitewater-rafting-ecuador/"
                        target="_blank">RAFTING</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/kayaking-ecuador/"
                        target="_blank">KAYAK ECUADOR</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/tours-in-ecuador/"
                        target="_blank">DAY
                        TOURS</a></li>
                <li class="nav-item menuItem"><a
                        href="https://www.imagineecuador.com/tour-operators-ecuador-quito-agency-travel-guide/"
                        target="_blank">PACKAGES</a></li>
                <li class="nav-item menuItem"><a
                        href="https://www.imagineecuador.com/mountain-climbing-ecuador-tours-trips/"
                        target="_blank">CLIMBING
                        ECUADOR</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/tours-in-banos/"
                        target="_blank">TOUR BANOS</a></li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/tours_rates-html/"
                        target="_blank">RATES</a>
                </li>
                <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/travel-blog-ecuador/"
                        target="_blank">BLOG</a>
                </li>
                <li class="nav-item menuItem"><a
                        href="https://www.imagineecuador.com/contact-us-tour-operator-travel-agency-banos/"
                        target="_blank">CONTACT</a></li>
            </ul>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>


    </div>
    <div style="background-color: #00000066;" class="responsiveHeader">
        <div class="container green containerHeader"
            style="justify-content: space-between; flex-direction: row!important; align-items: center; padding: 0 30px">
            <img src="https://www.imagineecuador.com/wp-content/uploads/2021/10/cropped-Recurso-1-4.png" width="51" />
            <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="align-items: center;">
                <i class="fa fa-bars fa-2x" style="padding-left: 20px;"></i><span style="padding-left:5px ;">Menu</span>
            </a>
        </div>
    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/" target="_blank">HOME</a></li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/galapagos-tours-cruises/"
                target="_blank">GALAPAGOS</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/amazon-tours/" target="_blank">AMAZON
                TOURS</a></li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/whitewater-rafting-ecuador/"
                target="_blank">RAFTING</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/kayaking-ecuador/" target="_blank">KAYAK
                ECUADOR</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/tours-in-ecuador/" target="_blank">DAY
                TOURS</a></li>
        <li class="nav-item menuItem"><a
                href="https://www.imagineecuador.com/tour-operators-ecuador-quito-agency-travel-guide/"
                target="_blank">PACKAGES</a></li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/mountain-climbing-ecuador-tours-trips/"
                target="_blank">CLIMBING
                ECUADOR</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/tours-in-banos/" target="_blank">TOUR
                BANOS</a></li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/tours_rates-html/"
                target="_blank">RATES</a>
        </li>
        <li class="nav-item menuItem"><a href="https://www.imagineecuador.com/travel-blog-ecuador/"
                target="_blank">BLOG</a>
        </li>
        <li class="nav-item menuItem"><a
                href="https://www.imagineecuador.com/contact-us-tour-operator-travel-agency-banos/"
                target="_blank">CONTACT</a></li>
    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-not-granted',
  templateUrl: './access-not-granted.component.html',
  styleUrls: ['./access-not-granted.component.scss'],
})
export class AccessNotGrantedComponent implements OnInit {
  counter: number = 10;
  POSITION_COMPANY_NAME = 1;
  companyName = this.location.path().split('/')[this.POSITION_COMPANY_NAME];
  constructor(private location: Location, private router: Router) {}
  ngOnInit() {
    this.goHome();
  }
  goHome() {
    setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        this.router.navigate([this.companyName]);
      }
    }, 1000);
  }
}

<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div style="background-color: #dc834e;" class="contactHeader">
        <div class="container green containerHeader">
            <div style="font-size: 18px;display: flex;">
                <b>Síguenos en: </b>
                <span class="span-icons">
                    <a href="https://www.facebook.com/mytriptoecuador/" target="_blank"><i class="fa fa-facebook-square"
                            style="color: black;"></i></a>
                </span>
                <span class="span-icons">
                    <a href="https://www.instagram.com/mytriptoecuador/" target="_blank"><i class="fa fa-instagram"
                            style="color: black;"></i></a>
                </span>
            </div>
            <div>
                <a style="text-decoration: none;" href="tel:0987273727" target="_blank">
                    <i class="fa fa-phone" style="padding: 0 10px;"></i>
                    Call Center: +593-9-8727-3727</a><span style="padding: 10px; color: white;">|</span>
                <a href="mailto:info@mytriptoecuador.ec" target="_blank">info@mytriptoecuador.ec</a>
            </div>


        </div>
    </div>

    <div #header1 class="container green containerMenu desktopMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://mytriptoecuador.ec/" class="navbar-brand"
            style="color: black!important; font-size: 0.95em;" target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <img src="https://mytriptoecuador.ec/wp-content/uploads/2022/09/LOGO-Photoroom-2.webp" style="height: 69px;"
                alt="logo" class="brand-logo">
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/" target="_blank">Home</a></li>
                <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/our-services/" target="_blank">Our
                        services</a></li>
                <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/galapagos/"
                        target="_blank">Destinations</a></li>
                <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/blog/" target="_blank">Blog</a>
                </li>
                <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/about-us/" target="_blank">About
                        us</a></li>
                <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/contact/" target="_blank">Contact
                        us</a></li>
            </ul>
        </div>
        <div style="width: 200px;">
            <a href="https://mytriptoecuador.ec/contact/" target="_blank" class="rightButton">Contact us</a>
        </div>
        <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart-o"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
    </div>
    <div class="responsiveContact">
        <div class="container green containerHeader"
            style="flex-direction: row;justify-content: center;font-size: 1.4em; background-color: #dc834e;">
            <div>
                <a style="text-decoration: none;" href="tel:0987273727" target="_blank">
                    <i class="fa fa-phone" style="padding: 0 20px;"></i>
                </a>
                <a href="mailto:info@mytriptoecuador.ec" target="_blank"><i class="fa fa-envelope-o"
                        style="padding: 0 20px;"></i>
                </a>
            </div>
            <span style="padding: 10px 0; color: white;">|</span>
            <div style="display: flex;">
                <span>
                    <a href="https://www.facebook.com/mytriptoecuador/" target="_blank"><i class="fa fa-facebook-square"
                            style="padding: 0 20px;"></i></a>
                </span>
                <span>
                    <a href="https://www.instagram.com/mytriptoecuador/" target="_blank"><i class="fa fa-instagram"
                            style="padding: 0 20px;"></i></a>
                </span>
            </div>
        </div>
    </div>
    <div style="background-color: white;" class="responsiveHeader" style="align-items: center;">
        <div style="margin-left: 1.5em;">
            <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
                role="tooltip" id="tooltip164687" x-placement="right"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
                <div class="arrow" style="top: 8px;"></div>
                <div class="tooltip-inner">Your Logo Here</div>
            </div>
            <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
                data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
                <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
            </a>
            <a *ngIf="this.companyParamsService.getWebSite()" href="https://mytriptoecuador.ec/" class="navbar-brand"
                style="color: black!important; font-size: 0.95em;" target="_blank">
                <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
                <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
                <img src="https://mytriptoecuador.ec/wp-content/uploads/2022/09/LOGO-Photoroom-2.webp" alt="logo"
                    class="brand-logo">
            </a>
        </div>

        <div class="container green containerHeader" style="justify-content: end; flex-direction: row!important;">
            <a class="menuButton" (click)="renderMenu()" href="javascript:void()"
                style="margin-right: 1.5em; color: white; border-radius: 7px;">
                <i class="fa fa-bars fa-2x" style="padding: 7px;"></i>
            </a>
        </div>
    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/" target="_blank">Home</a></li>
        <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/our-services/" target="_blank">Our
                services</a></li>
        <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/galapagos/" target="_blank">Destinations</a>
        </li>
        <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/blog/" target="_blank">Blog</a>
        </li>
        <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/about-us/" target="_blank">About
                us</a></li>
        <li class="nav-item menuItem"><a href="https://mytriptoecuador.ec/contact/" target="_blank">Contact
                us</a></li>
    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>
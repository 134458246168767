import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AccessNotGrantedComponent } from './components/access-not-granted/access-not-granted.component';
import { LoadingGroupedComponent } from './components/loading-grouped/loading-grouped.component';
import { CustomHeaderComponent } from './components/header/components/custom-header/custom-header.component';
import { GalapagosproComponent } from './components/header/components/custom-header/templates/galapagospro/galapagospro.component';
import { GalavailComponent } from './components/header/components/custom-header/templates/galavail/galavail.component';
import { FourworldstravelsComponent } from './components/header/components/custom-header/templates/fourworldstravels/fourworldstravels.component';
import { GalapagosdeComponent } from './components/header/components/custom-header/templates/galapagosde/galapagosde.component';
import { FusiontravelecuadorComponent } from './components/header/components/custom-header/templates/fusiontravelecuador/fusiontravelecuador.component';
import { GloballandtourComponent } from './components/header/components/custom-header/templates/globallandtour/globallandtour.component';
import { GalapagosinsidersComponent } from './components/header/components/custom-header/templates/galapagosinsiders/galapagosinsiders.component';
import { HakuadventureComponent } from './components/header/components/custom-header/templates/hakuadventure/hakuadventure.component';
import { AdventurejourneysComponent } from './components/header/components/custom-header/templates/adventurejourneys/adventurejourneys.component';
import { MytriptoecuadorComponent } from './components/header/components/custom-header/templates/mytriptoecuador/mytriptoecuador.component';
import { CamiadventuresComponent } from './components/header/components/custom-header/templates/camiadventures/camiadventures.component';
import { EcuatravelingComponent } from './components/header/components/custom-header/templates/ecuatraveling/ecuatraveling.component';
import { QuitobiketoursComponent } from './components/header/components/custom-header/templates/quitobiketours/quitobiketours.component';
import { GalatrailsComponent } from './components/header/components/custom-header/templates/galatrails/galatrails.component';
import { QuitotourbusComponent } from './components/header/components/custom-header/templates/quitotourbus/quitotourbus.component';
import { NaturegalapagosComponent } from './components/header/components/custom-header/templates/naturegalapagos/naturegalapagos.component';
import { QuichuanativetravelComponent } from './components/header/components/custom-header/templates/quichuanativetravel/quichuanativetravel.component';
import { ImagineecuadorComponent } from './components/header/components/custom-header/templates/imagineecuador/imagineecuador.component';
import { GalapagoslastminutesComponent } from './components/header/components/custom-header/templates/galapagoslastminutes/galapagoslastminutes.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    AccessNotGrantedComponent,
    LoadingGroupedComponent,
    CustomHeaderComponent,
    GalapagosproComponent,
    GalavailComponent,
    FourworldstravelsComponent,
    GalapagosdeComponent,
    FusiontravelecuadorComponent,
    GloballandtourComponent,
    GalapagosinsidersComponent,
    HakuadventureComponent,
    AdventurejourneysComponent,
    MytriptoecuadorComponent,
    CamiadventuresComponent,
    EcuatravelingComponent,
    QuitobiketoursComponent,
    GalatrailsComponent,
    QuitotourbusComponent,
    NaturegalapagosComponent,
    QuichuanativetravelComponent,
    ImagineecuadorComponent,
    GalapagoslastminutesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    LoadingGroupedComponent
  ]
})
export class CoreModule { }

<app-galapagospro *ngIf="company=='galapagospro'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-galapagospro>
<app-galavail *ngIf="company=='galavail'"></app-galavail>
<app-fourworldstravels *ngIf="company=='fourworldstravels'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-fourworldstravels>
<app-fusiontravelecuador *ngIf="company=='fusiontravelecuador'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-fusiontravelecuador>
<app-galapagosde *ngIf="company=='galapagosde'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-galapagosde>
<app-globallandtour *ngIf="company=='globallandtour'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-globallandtour>
<app-galapagosinsiders *ngIf="company=='galapagosinsiders'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-galapagosinsiders>
<app-hakuadventure *ngIf="company=='hakuadventure'" (headerHeight1)="getHeight1($event)"
    (headerHeight)="getHeight($event)"></app-hakuadventure>
<app-adventurejourneys *ngIf="company=='adventurejourneys'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-adventurejourneys>
<app-mytriptoecuador *ngIf="company=='mytriptoecuador'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-mytriptoecuador>
<app-camiadventures *ngIf="company=='camiadventures'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-camiadventures>
<app-ecuatraveling *ngIf="company=='ecuatraveling'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-ecuatraveling>
<app-quitobiketours *ngIf="company=='quitobiketours'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-quitobiketours>
<app-galatrails *ngIf="company=='galatrails'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-galatrails>
<app-quitotourbus *ngIf="company=='quitotourbus'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-quitotourbus>
<app-quichuanativetravel *ngIf="company=='quichuanativetravel'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-quichuanativetravel>
<app-naturegalapagos *ngIf="company=='naturegalapagos'" (headerHeight)="getHeight($event)"
    (headerHeight1)="getHeight1($event)"></app-naturegalapagos>
<app-imagineecuador *ngIf="company=='imagineecuador'" (headerHeight1)="getHeight1($event)"
    (headerHeight)="getHeight($event)"></app-imagineecuador>
<app-galapagoslastminutes *ngIf="company=='galapagoslastminutes'" (headerHeight)="getHeight($event)"
    (headerHeight)="getHeight1($event)"></app-galapagoslastminutes>
<nav #header class="navbar navbar-light navbar-expand-md py-md-2 fixed-top galavail-background"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div class="container green container-galavail">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="https://quitotourbus.com/wp-content/uploads/2019/10/logo.png" height="50" alt="logo"
                class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://quitotourbus.com/" class="navbar-brand"
            target="_blank">
            <img id="logoMenu" src="https://quitotourbus.com/wp-content/uploads/2019/10/logo.png" height="50" alt="logo"
                class="brand-logo logoMenu">
        </a>
        <button class="navbar-toggler" type="button" style="background-color: azure;" data-toggle="collapse"
            data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            <!-- /.navbar-toggler-icon -->
        </button>
        <div class="navbar-collapse collapse" id="navbarNav" style="padding-left: 2em; justify-content: center;">
            <ul class="navbar-nav">
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/quito-city-tour" target="_blank"><span
                                class="galavail-menu-item">Tours</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/transporte-aeropuerto-quito" target="_blank"><span
                                class="galavail-menu-item">Transporte Aeropuerto – Quito</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/que-hacer-en-quito" target="_blank"><span
                                class="galavail-menu-item">Guía del Ecuador</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/puntos-de-venta" target="_blank"><span
                                class="galavail-menu-item">Puntos de venta</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/promociones" target="_blank"><span
                                class="galavail-menu-item">Promociones</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/contacto" target="_blank"><span
                                class="galavail-menu-item">Contacto</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/en/things-to-do-in-quito" target="_blank"><img class="flag"
                                src="../../../../../../../../assets/images/es.svg"
                                alt="Español"><span class="responsive-span"> Español</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2 listItem" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://quitotourbus.com/que-hacer-en-quito" target="_blank"><img class="flag"
                                src="../../../../../../../../assets/images/en-flag.svg"
                                alt="English"><span class="responsive-span"> English</span></a>
                    </p>
                </li>
            </ul>
            <div class="tooltip fade show bs-tooltip-bottom" *ngIf="companyParamsService.getHasLogoInformation()"
                role="tooltip" id="tooltip644128" x-placement="bottom"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1051px, 44px, 0px);">
                <div class="arrow" style="left: 71px;"></div>
                <div class="tooltip-inner">Your Telephone Here</div>
            </div>
        </div>

        <div class="lang-container-nav">
            <div>
                <a href="https://globallandtour.com/tel:+593999712102" target="_blank"><i class="fa fa-search"></i></a>
            </div>
        </div>
        <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart-o"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
    </div>
</nav>
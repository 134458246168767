<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div style="background-color: #23475F;" class="contactHeader">
        <div class="container green containerHeader">
            <div>
                <a href="https://www.facebook.com/FourWorldsTourOperator?locale=es_LA"><i class="fa fa-facebook"
                        style="padding: 0 10px; font-size: 1.25em;"></i></a>
                <a href="https://www.instagram.com/fourworlds.ec?igsh=Z3RlcmZ1Z3BrbHJk"><i class="fa fa-instagram"
                        style="padding: 0 10px; font-size: 1.25em;"></i></a>
                <a href="mailto:info@fourworldsecuador.com"><i class="fa fa-envelope-o"
                        style="padding: 0 10px; font-size: 1.25em;"></i></a>
            </div>
            <div>
                <a style="font-weight:bolder; text-decoration: none;"
                    href="https://api.whatsapp.com/send?phone=593963064486&amp;text=Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n"
                    target="_blank">
                    <i class="fa fa-whatsapp" style="padding: 0 10px; font-size: 1.5em;"></i>
                    +593 96 306 4486</a>
            </div>
            <div>
                <a href="mailto:info@fourworldsecuador.com" target="_blank">info@fourworldsecuador.com</a>
            </div>
            <div>
                <a href="https://fourworldsecuador.com/es/"><img src="../../../../../../../../assets/images/es.svg"
                        style="padding: 0 10px;"></a>
                <a href="https://fourworldsecuador.com/en/"><img src="../../../../../../../../assets/images/gb.svg"
                        style="padding: 0 10px;"></a>
            </div>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>
    </div>

    <div #header1 class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://fourworldsecuador.com/" class="navbar-brand"
            target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <img src="https://fourworldsecuador.com/wp-content/uploads/2022/12/logo.png" height="50" alt="logo"
                class="brand-logo">
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/" target="_blank">Home</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/about-us/" target="_blank">About
                        us</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/ecuador/"
                        target="_blank">Ecuador</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/ba_location/peru/"
                        target="_blank">Perú</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/ba_location/colombia/"
                        target="_blank">Colombia</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/tailor-made-tours/"
                        target="_blank">Tailor-made tours</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/transportation-service/"
                        target="_blank">Transportation service</a></li>
                <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/contact/"
                        target="_blank">Contacts</a></li>
            </ul>
        </div>
        <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="padding-right: 1em;">
            <i class="fa fa-bars fa-2x"></i>
        </a>
        <a href="https://fourworldsecuador.com/?s=" target="_blank"
            style="padding-left: 1em; border-left:1px solid #82828A;">
            <i class="fa fa-search fa-2x"></i>
        </a>

    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/" target="_blank">Home</a></li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/about-us/" target="_blank">About us</a>
        </li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/ecuador/" target="_blank">Ecuador</a></li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/ba_location/peru/" target="_blank">Perú</a>
        </li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/ba_location/colombia/"
                target="_blank">Colombia</a></li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/tailor-made-tours/"
                target="_blank">Tailor-made tours</a></li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/transportation-service/"
                target="_blank">Transportation service</a></li>
        <li class="nav-item menuItem"><a href="https://fourworldsecuador.com/contact/" target="_blank">Contacts</a></li>
    </ul>
</nav>

<nav #galHeader class="navbar navbar-light navbar-expand-md py-md-2 fixed-top galavail-background"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}" id="nvbar">
    <div class="container green container-galavail">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="{{this.companyParamsService.getWebSite()}}"
            class="navbar-brand" target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <img id="logoMenu" [src]=this.companyParamsService.getLogo() height="50" alt="logo"
                class="brand-logo logoMenu">
        </a>
        <button class="navbar-toggler" type="button" style="background-color: azure;" data-toggle="collapse"
            data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            <!-- /.navbar-toggler-icon -->
        </button>
        <div class="navbar-collapse collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://galavail.com" target="_blank"><span class="galavail-menu-item">Home</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://galavail.com/management-system.html" target="_blank"><span
                                class="galavail-menu-item">Management System</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://galavail.com/booking-platform.html" target="_blank"><span
                                class="galavail-menu-item">Booking Platform</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://galavail.com/ecommerce.html" target="_blank"><span
                                class="galavail-menu-item">eCommerce</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://galavail.com/about.html" target="_blank"><span class="galavail-menu-item">About
                                Us</span></a>
                    </p>
                </li>
            </ul>
            <div class="tooltip fade show bs-tooltip-bottom" *ngIf="companyParamsService.getHasLogoInformation()"
                role="tooltip" id="tooltip644128" x-placement="bottom"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1051px, 44px, 0px);">
                <div class="arrow" style="left: 71px;"></div>
                <div class="tooltip-inner">Your Telephone Here</div>
            </div>
        </div>

        <div class="lang-container-nav">
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
            <div class="lang-img-box">
                <a href="https://galavail.com" target="_blank"><img class="flag" src="../../../../../../../../assets/images/en-flag.svg"
                        alt="English"></a>
            </div>
            <a class="account-link nav-link" href="https://galavail.com/galavail-web/login.xhtml" target="_blank">Login</a>
            <div>
                <a class="blue-btn" href="https://galavail.com/#request-demo" target="_blank">GET A DEMO</a>
            </div>
        </div>
    </div>
</nav>
import { EventEmitter, Injectable } from '@angular/core';
import { isNullOrUndefined } from 'is-what';
import { MonthDay } from '../../core/models/month-day';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  public linked: any = {};
  public utilsService: EventEmitter<any> = new EventEmitter<any>();

  public static getDate(fullYear: number, month: number, day: number): Date {
    try {
      if (
        isNullOrUndefined(fullYear) ||
        isNullOrUndefined(month) ||
        isNullOrUndefined(day) ||
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31
      ) {
        throw 'No valid date params';
      }
      return new Date(fullYear, month - 1, day);
    } catch (error) {
      console.log('UtilClass.getDate()');
      console.error(error);
      throw error;
    }
  }
  public static getNumberOfDaysForMonth(month: number, year: number): number {
    try {
      return new Date(year, month, 0).getDate();
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  public static getOrdinalNumber(n: any): string {
    try {
      const s: string[] = ['th', 'st', 'nd', 'rd'];
      const v: number = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  public generateDateByStringYearAndMonthDate(stringDate: string): Date {
    const dateArray = stringDate.split(',');

    const year = Number(dateArray[0]);
    const month = Number(dateArray[1]);
    if (
      Number.isSafeInteger(year) &&
      Number.isSafeInteger(month) &&
      month >= 1 &&
      month <= 12
    ) {
      return UtilsService.generateDateByYearAndMonth(year, month);
    } else {
      return new Date();
    }
  }

  public static generateDateByYearAndMonth(year: number, month: number): Date {
    return new Date(year, month - 1);
  }

  public static generateDateByMiliseconds(
    milisecondsDate: number
  ): Date | null {
    try {
      return new Date(milisecondsDate);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static millisecondsToHours(milliseconds: number): number {
    try {
      return milliseconds / 1000 / 60 / 60;
    } catch (error) {
      console.error(error);
      throw Number;
    }
  }
  public static millisecondsToDays(milliseconds: number): number {
    try {
      return milliseconds / 1000 / 60 / 60 / 24;
    } catch (error) {
      console.log('UtilService.millisecondsToHDays()');
      console.error(error);
      throw Number;
    }
  }

  public static getArrayOfNumbers(
    numberOfElements: number,
    startfromZero: boolean
  ): number[] {
    if (numberOfElements === 0) {
      return [];
    }
    const resultArray: number[] = [];
    const startAt: number = startfromZero ? 0 : 1;
    const endAt: number = startfromZero
      ? numberOfElements - 1
      : numberOfElements;
    for (let i = startAt; i <= endAt; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  public static getArrayOfNumbersByRange(from: number, to: number): number[] {
    const resultArray: number[] = [];
    for (let i = from; i <= to; i++) {
      resultArray.push(i);
    }
    return resultArray;
  }

  /***
   * Given a string in the 2018-01-17 10:55:05 format, it converts it into a ISO format (2018-01-17T10:55:05Z)
   * si it can be used to create a new Date object in JS
   * @param (string) notISOStringDate
   */
  public static createIsoDateTimeString(notISOStringDate: string) {
    try {
      const arrayDate: string[] = notISOStringDate.split(' ');
      console.log(arrayDate);
      if (arrayDate.length !== 2) {
        return '';
      }
      console.log(arrayDate[0] + 'T' + arrayDate[1] + 'Z');
      return arrayDate[0] + 'T' + arrayDate[1] + 'Z';
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  public static createDateFromNotISOStringDate(
    notISOStringDate: string
  ): Date | null {
    try {
      const iSOStringDate: string =
        UtilsService.createIsoDateTimeString(notISOStringDate);
      if (iSOStringDate) {
        return null;
      }
      return new Date(iSOStringDate);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static padRight(
    stringToPad: string,
    width: number,
    character = '0'
  ): string {
    try {
      return stringToPad.length >= width
        ? stringToPad
        : new Array(width - stringToPad.length + 1).join(character) +
            stringToPad;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  public static generateMonthDays(month: number, year: number): MonthDay[] {
    try {
      const numberOfDaysForMonth: number = UtilsService.getNumberOfDaysForMonth(
        month,
        year
      );
      const resultMontDays: MonthDay[] = [];

      for (let i = 1; i <= numberOfDaysForMonth; i++) {
        const itemMonthDay: MonthDay = {
          value: i,
          name: UtilsService.padRight(i.toString(), 2),
        };
        resultMontDays.push(itemMonthDay);
      }

      return resultMontDays;
    } catch (error) {
      return [];
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { LayoutComponent } from './layout.component';
import { AgencyDashboardComponent } from './layouts/agency-dashboard/agency-dashboard.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    LayoutComponent,
    AgencyDashboardComponent,
    MainLayoutComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule
  ]
})
export class LayoutModule { }

<div class="row">
    <div class="col-12">
        <div class="cruise-list-card" *ngFor="let item of arrayLoading">
            <div class="promo-esq preload">
            </div>
            <div class="row no-gutters">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="cruise-img-box">
                        <img src="assets/images/cruises/loading-boat.gif" alt="img alt">
                        <div class="loading-row">
                            <div class="text w-100 loading-animate" style="height: 36px; margin: 0;"></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-sm-12">
                    <div class="p-3">
                        <div class="row">
                            <div class="col-lg-8 col-md-8 col-sm-12 ">

                                <div class="loading-row">
                                    <div class="text w-70 loading-animate" style="height: 36px;"></div>
                                </div>
                                <div class="loading-row">
                                    <div class="text w-100 loading-animate" style="height: 16px;"></div>
                                </div>
                                <div class="cruise-location">
                                    <div class="loading-row">

                                        <div class="text w-100 loading-animate" style="margin-bottom: 0px;height: 13px;"></div>
                                        <div class="text w-100 loading-animate" style="margin-bottom: 0px;height: 13px;"></div>
                                        <div class="text w-100 loading-animate" style="margin-bottom: 0px;height: 13px;"></div>
                                        <div class="text w-100 loading-animate" style="margin-bottom: 0px;height: 13px;"></div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <div class="small " style="display: flex;">

                                        <div class="loading-row">
                                            <div class="text loading-animate" style="height: 30px; margin-right: 5px; width: 81px"></div>
                                        </div>
                                        <div class="loading-row" style="display: flex;">
                                            <div class="text w-100 loading-animate" style="height: 13px;    margin-top: 19px;
                                            "></div>
                                        </div>
                                    </div>
                                    <div class="loading-row">

                                        <div class="text w-100 loading-animate" style="height: 15px;"></div>
                                        <div class="text w-100 loading-animate" style="height: 15px;"></div>
                                    </div>

                                    <div class="loading-row">
                                        <div class="text w-100 loading-animate" style="background-color: #DB2630;" style="height: 36px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>



</div>
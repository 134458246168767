<nav #header class="navbar navbar-light navbar-expand-md py-md-2 fixed-top galavail-background"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}" style="background-color: black !important;">
    <div class="container green container-galavail">
        <a href="https://www.ecuatraveling.com/"
            class="navbar-brand" target="_blank">
            <img id="logoMenu" src="https://www.ecuatraveling.com/images/layout/logo-v-fb.svg" alt="logo"
                class="brand-logo logoMenu c-logo">
        </a>

        <div class="navbar-collapse collapse" id="navbarNav" style="padding-left: 5em;">
            <ul class="navbar-nav">
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://www.ecuatraveling.com/latin-america-tours" target="_blank"><span
                                class="galavail-menu-item">LATIN AMERICA</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://www.ecuatraveling.com/europe" target="_blank"><span
                                class="galavail-menu-item">EUROPE</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://www.ecuatraveling.com/galapagos-tours" target="_blank"><span
                                class="galavail-menu-item">GALAPAGOS</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://www.ecuatraveling.com/hosted-trips" target="_blank"><span
                                class="galavail-menu-item">HOSTED TRIPS</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://www.ecuatraveling.com/about" target="_blank"><span
                                class="galavail-menu-item">ABOUT-US</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://www.ecuatraveling.com/travel-blog-ecuador" target="_blank"><span
                                class="galavail-menu-item">TRAVEL BLOG</span></a>
                    </p>
                </li>
            </ul>
            <div class="tooltip fade show bs-tooltip-bottom" *ngIf="companyParamsService.getHasLogoInformation()"
                role="tooltip" id="tooltip644128" x-placement="bottom"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1051px, 44px, 0px);">
                <div class="arrow" style="left: 71px;"></div>
                <div class="tooltip-inner">Your Telephone Here</div>
            </div>
        </div>

        <div class="lang-container-nav">
            <div>
                <a href="https://www.tripadvisor.com/Attraction_Review-g294308-d6924549-Reviews-Ecuatraveling-Quito_Pichincha_Province.html"
                    target="_blank"><img src="https://www.ecuatraveling.com/images/layout/tripadvisor-ico.svg"
                        class="s-icon"></a>
            </div>
            <div>
                <a href="https://wa.me/593959641843" target="_blank"><img
                        src="https://www.ecuatraveling.com/images/layout/whatsapp-ico.svg" class="s-icon"></a>
            </div>
            <div>
                <a href="https://cal.com/info-ecuatraveling-353n99/" target="_blank"
                    class="call-button"><span><b>REQUEST A CALL</b></span></a>
            </div>
        </div>
        <button class="navbar-toggler" type="button" style="color: white;" data-toggle="collapse"
            data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
            <!-- /.navbar-toggler-icon -->
        </button>
        <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart-o"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
    </div>
</nav>
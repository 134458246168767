import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClientWsGalavailModule } from 'client-ws-galavail';
import { CreditCardLibModule } from 'credit-card-lib';
import { NgWebflowModule } from 'ng-webflow';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { LocalStorageModule } from 'angular-2-local-storage';
import { BaseComponent } from './shared/base/base.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';


@NgModule({
  declarations: [AppComponent, BaseComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ClientWsGalavailModule,
    NgWebflowModule,
    NgbModule,
    CreditCardLibModule,
    LayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LocalStorageModule.forRoot({
      prefix: '',
      storageType: 'localStorage',
    }),
    StoreModule.forRoot({}, {}),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { ClientWsGalavailService } from 'client-ws-galavail';
import { GalavailRestHttpMethod } from 'src/app/core/enums/galavail-rest-http-method';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  pathService = environment.pathService;
  pathServiceAvailability = environment.pathServiceAv;
  constructor(private clientWsGalavailService: ClientWsGalavailService) {}

  requestPost(requestServiceName: string, body: any): Observable<any> {
    return this.clientWsGalavailService.request<any[]>(
      `${this.pathService}/${requestServiceName}`,
      GalavailRestHttpMethod.POST,
      undefined,
      body
    );
  }

  requestPostAv(requestServiceName: string, body: any): Observable<any> {
    return this.clientWsGalavailService.request<any[]>(
      `${this.pathServiceAvailability}/${requestServiceName}`,
      GalavailRestHttpMethod.POST,
      undefined,
      body
    );
  }

  requestGet(requestServiceName: string): Observable<any> {
    return this.clientWsGalavailService.request<any[]>(
      `${this.pathService}/${requestServiceName}`,
      GalavailRestHttpMethod.GET,
      undefined
    );
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  padding:any;
  headerHeightInput:any;
  constructor() { }

  ngOnInit(): void {
    this.padding = this.headerHeightInput - 81;
  }

  getHeight(height:any){
    this.headerHeightInput = height
  }

  ngAfterContentChecked() {
    this.padding = this.headerHeightInput - 81;

  }
}

import { Component, OnInit } from '@angular/core';
import { CompanyParamsService } from 'src/app/core/services/company-params.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  padding:any;
  headerHeightInput:any;
  headerHeightInput1:any;

  constructor(
    public companyParamsService: CompanyParamsService,
  ) { }

  ngOnInit(): void {
    this.padding = this.headerHeightInput - 81;
  }

  getHeight(height:any){
    this.headerHeightInput = height;
  }

  getHeight1(height:any){
    this.headerHeightInput1 = height;
  }

  ngAfterContentChecked() {
    this.padding = this.headerHeightInput - 81;
    localStorage.setItem('height-'+this.companyParamsService.getCode(),this.headerHeightInput);
    localStorage.setItem('height1-'+this.companyParamsService.getCode(),this.headerHeightInput1);
  }
}

<nav #header class="fixed-top headerStyle" style="background-color: #233080;"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div class="container green">
        <div class="containerHeaderTop">
            <div class="social-icons">
                <a href="https://www.facebook.com/naturegalapagosecuador"><i class="fa fa-facebook"></i></a>
                <a href="https://www.instagram.com/naturegalapagos/"><i class="fa fa-instagram"></i></a>
                <a
                    href="https://www.tripadvisor.co/Attraction_Review-g294308-d13231011-Reviews-Nature_Galapagos_Ecuador-Quito_Pichincha_Province.html"><i
                        class="fa fa-tripadvisor"></i></a>
                <a href="https://twitter.com/naturegalapagos"><i class="fa fa-twitter"></i></a>
                <a href="https://www.pinterest.com/Naturegalapagos/"><i class="fa fa-pinterest"></i></a>
            </div>
            <div class="right-icons">
                <a class="language" href="https://naturegalapagos.com/" target="_blank">LANGUAGE</a>
                <a href="https://naturegalapagos.com/cart/" target="_blank">
                    <i class="fa fa-shopping-cart"></i>
                </a>
                <a href="https://naturegalapagos.com/" target="_blank">
                    <i class="fa fa-search"></i>
                </a>
            </div>
        </div>
        <div class="containerMenu navbar-collapse collapse">
            <div class="call-container">
                <div class="logo-desktop">
                    <a href="https://naturegalapagos.com/" target="_blank">
                        <img class="logo"
                            src="https://naturegalapagos.com/wp-content/uploads/2016/10/nature-galapagos-ecuador-logo-local-tour-operator.png.webp" />
                    </a>
                </div>
                <div class="contactContainer">
                    <ul class="navbar-nav contact">
                        <li class="nav-item menuItem1" style="text-align: right;">
                            <i class="fa fa-phone"></i>
                            <span>Call us for a Free quote</span><br><a href="tel:+18453453204">+1 845 345 3204</a>
                        </li>
                    </ul>
                </div>
                <div class="menuButton">
                    <a (click)="renderMenu()" href="javascript:void()"><i class="fa fa-bars"></i></a>
                </div>
            </div>
            <div class="logo-mobile">
                <a href="https://naturegalapagos.com/" target="_blank">
                    <img class="logo"
                        src="https://naturegalapagos.com/wp-content/uploads/2016/10/nature-galapagos-ecuador-logo-local-tour-operator.png.webp" />
                </a>
            </div>
        </div>
        <div #header1 class="containerHeader" [ngClass]="showMenu ? 'showMenu':''">
            <ul class="navbar-nav" [ngClass]="showMenu ? 'menu-backcolor':''">
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/" target="_blank">HOME</a></li>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/galapagos-cruises/"
                        target="_blank">GALAPAGOS CRUISES</a></li>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/galapagos-tours/"
                        target="_blank">GALAPAGOS TOURS</a></li>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/galapagos-diving/"
                        target="_blank">GALAPAGOS DIVING</a></li>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/ecuador-tours/"
                        target="_blank">ECUADOR TOURS</a>
                </li>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/ecuador-tours/quito-tours/"
                        target="_blank">DAYTOURS QUITO</a>
                </li>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/galapagos-travel-agency/"
                        target="_blank">ABOUT US</a>
                <li class="nav-item menuItem"><a href="https://naturegalapagos.com/contact-us/" target="_blank">CONTACT
                        US</a>
            </ul>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>
    </div>
</nav>
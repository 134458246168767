import { Component, OnInit, Output, ViewChild, EventEmitter, ElementRef, AfterViewInit  } from '@angular/core';
import { CompanyParamsService } from 'src/app/core/services/company-params.service';
import { Utils } from 'src/app/shared/utils';
import { BaseComponent } from 'src/app/shared/base/base.component';
import { Injector } from '@angular/core';
import { TypeListMenu } from 'src/app/core/enums/type-list-menu';

@Component({
  selector: 'app-galavail',
  templateUrl: './galavail.component.html',
  styleUrls: ['./galavail.component.scss']
})
export class GalavailComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  @ViewChild('galHeader') headerRef!: ElementRef;
  typeListMenu = TypeListMenu;
  renderMenu:boolean = false;
  company?:any;
  constructor(
    public companyParamsService: CompanyParamsService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  goFavorites() {
    const urlFavorites: any =
      this.companyParamsService.getNameUrl() +
      '/wishlist';
    Utils.openNewTab(urlFavorites);
  }

  ngAfterViewInit() {
    var height = this.headerRef.nativeElement.offsetHeight;
    this.headerHeight.emit(height);
  }
}

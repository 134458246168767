<nav #header class="navbar navbar-light navbar-expand-md py-md-2 fixed-top galavail-background"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div class="container green container-galavail">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="https://globallandtour.com/wp-content/uploads/2023/01/g1.png" height="50" alt="logo"
                class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="{{this.companyParamsService.getWebSite()}}"
            class="navbar-brand" target="_blank">
            <img id="logoMenu" src="https://globallandtour.com/wp-content/uploads/2023/01/g1.png" height="50" alt="logo"
                class="brand-logo logoMenu">
        </a>
        <button class="navbar-toggler" type="button" style="background-color: azure;" data-toggle="collapse"
            data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            <!-- /.navbar-toggler-icon -->
        </button>
        <div class="navbar-collapse collapse" id="navbarNav" style="padding-left: 5em;">
            <ul class="navbar-nav">
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://globallandtour.com/" target="_blank"><span
                                class="galavail-menu-item">Inicio</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://globallandtour.com/nosotros/" target="_blank"><span
                                class="galavail-menu-item">Nosotros</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://globallandtour.com/galapagos/" target="_blank"><span
                                class="galavail-menu-item">Galapagos</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://globallandtour.com/ecuador/" target="_blank"><span
                                class="galavail-menu-item">Ecuador</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://globallandtour.com/otros-paises/" target="_blank"><span
                                class="galavail-menu-item">Otros
                                paises</span></a>
                    </p>
                </li>
                <li class="nav-item dropdown py-md-2" style="display: flex;">
                    <p class="tollfree">
                        <a href="https://globallandtour.com/" target="_blank"><span class="galavail-menu-item"><img
                                    class="flag" src="../../../../../../../../assets/images/es.svg"
                                    alt="Español"></span></a>
                    </p>
                </li>
            </ul>
            <div class="tooltip fade show bs-tooltip-bottom" *ngIf="companyParamsService.getHasLogoInformation()"
                role="tooltip" id="tooltip644128" x-placement="bottom"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1051px, 44px, 0px);">
                <div class="arrow" style="left: 71px;"></div>
                <div class="tooltip-inner">Your Telephone Here</div>
            </div>
        </div>

        <div class="lang-container-nav">
            <div style="border-right: 1px solid #ddd !important;">
                <a href="https://globallandtour.com/tel:+593999712102" target="_blank"><i
                        class="fa fa-mobile fa-2x"><span><b>+593999712102</b></span></i></a>
            </div>
            <div>
                <a href="https://globallandtour.com/#triply-login-form" target="_blank"><i class="fa fa-user-o"
                        style="font-size: 1.5em;"></i></a>
            </div>
            <div>
                <a href="https://globallandtour.com/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjE1ODkiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D"
                    target="_blank"><i class="fa fa-info" style="font-size: 1.5em;"></i></a>
            </div>
        </div>
        <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
            <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                <i class="fa fa-heart-o"></i>
            </a>
            <span class="tooltip-text">wishlist</span>
        </div>
    </div>
</nav>
import { CatalogueFilters } from './catalogue-filters';
import { ScriptJs } from '../services/company-params.service';
export interface Company {
  code: string;
  email: string; // request
  agency: string;
  footerColor: string;
  headerColor: string;
  infoEmail: string; // web
  logo: string;
  logoFade: string;
  mainColor: string;
  name: string;
  phone: string;
  privacyUrl: string;
  secondaryColor: string;
  webSite: string;
  searcher?: any;
  nameRequest: string;
  bannerImg: string;
  emailEcommerce: string; // ecommerce
  tertiaryColor: string;

  whatsappNumber?: string;
  facebookLink?: string;
  twitterLink?: string;
  googleLink?: string;
  linkedinLink?: string;
  youtubeLink?: string;
  tripadvisorLink?: string;
  pinterestLink?: string;
  instagramLink?: string;
  statusEcommerce: boolean;
  visibleEcommerce: boolean;
  visibleInquire: boolean;
  listadoMenu: ListadoMenu[];
  visiblePago: boolean;

  slogan?: Slogan;
  listYearMonth: LisYearMonth[];
  rateAirTicketAduld: number;
  rateAirTicketChild: number;
  hasLogoInformation: boolean;
  percentPartialPayment: number;

  fadeMenu: boolean;
  sloganMenu: string;
  sloganPrice: SlogaPrice;

  paymentMethods: PaymentMethod[];
  partialPayment: boolean;
  linkFaq: string;

  minDaysPartialPayment: number;
  listCategories: CatalogueFilters[];
  listPromotions: CatalogueFilters[];
  listFilters: CatalogueFilters[];
  visibleFilterPrices: boolean;
  footerMessage: string;

  visibleCharterOnly: boolean;
  visibleGroupsOnly: boolean;
  headerActions: HeaderActions;
  //jsonGroupType: boolean;
  recommended: boolean;
  visibleCruiserProfile: boolean;
  visiblePrices: boolean ;

  airTicketsConditions: string;
  airTicketsContent: string;
  airTicketsTitle: string;

  filtersSearch:any;

  visibleFilterSummary:boolean;

}

export interface FiltersSearch {
  date:any;
  adults:any;
  children:any;
  interests:Interest;
}


export interface Interest {
  name: any;
  status: any;
  filtersSearch: FilterSearch[];
}
export interface ListadoMenu {
  name: string;
  value: string;
  type: String;
  order?: number;
  spanStyle: string;
}

export interface HeaderActions {
  socialMediaList: SocialMedia[];
  orientation: string;
  position: string;
  scripts: ScriptJs[];
  totalResults: boolean;
  viewModes: any;
  headerTemplate:string;
  detailInfoTemplate:string;

}

export interface LisYearMonth {
  code: number;
  description: string;
}
export interface SocialMedia {
  name: string;
  image: string;
  description: string;
  link: string;
}
export interface Slogan {
  image: string;
  label: string;
  items: string[];
  widthItem: string;
}
export interface SlogaPrice {
  image: string;
  label: string;
  visibleTermsCondition: boolean;
}
export interface PaymentMethod {
  code: number;
  type: number;
  name: string;
  order: number;
  description: string;
  linkInfo: string;
  selected?: boolean;
  icons: any[];
  objectConfiguration?: any;
}

export interface FilterSearchValue{
  name: string;
  status: string;
}

export interface FilterSearch {
  filterName: string;
  filterSearchValue: FilterSearchValue;
}


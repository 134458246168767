<nav #header *ngIf="renderMenu" class=" fixed-top headerStyle"
    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}">
    <div style="background-color: #34c759;" class="contactHeader">
        <div class="container green containerHeader">
            <div>
                <a href="https://www.facebook.com/Fusiontravel.ecuador"><i class="fa fa-facebook"
                        style="padding: 0 10px;"></i></a>
                <a href="#"><i class="fa fa-twitter" style="padding: 0 10px;"></i></a>
                <a href="https://www.instagram.com/fusiontravel.ecuador/"><i class="fa fa-instagram"
                        style="padding: 0 10px;"></i></a>
            </div>
            <div>
                <a style="font-weight:bolder; text-decoration: none;"
                    href="https://fusiontravelecuador.com/tel:0967987391" target="_blank">
                    <i class="fa fa-phone" style="padding: 0 10px;"></i>
                    0967987391</a>
            </div>
            <div>
                <a href="mailto:info@fourworldsecuador.com" target="_blank">info@fourworldsecuador.com</a>
            </div>
        </div>
    </div>

    <div #header1 class="container green containerMenu">
        <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
            role="tooltip" id="tooltip164687" x-placement="right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
            <div class="arrow" style="top: 8px;"></div>
            <div class="tooltip-inner">Your Logo Here</div>
        </div>
        <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
            data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
            <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://fusiontravelecuador.com/" class="navbar-brand"
            style="color: black!important; font-size: 0.95em;" target="_blank">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
            <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
            <img src="https://fusiontravelecuador.com/wp-content/uploads/2024/10/WEB.png" height="50" alt="logo"
                class="brand-logo">
            <span style="padding-left: 15px;">FUSION TRAVEL ECUADOR</span>
        </a>
        <div class="containerMenu navbar-collapse collapse" style="width: inherit;">
            <ul class="navbar-nav menuContainer" style="width: inherit;">
                <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/" target="_blank">Home</a></li>
                <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/destination/"
                        target="_blank">Ecuador</a></li>
                <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/2024/10/17/galapagos/"
                        target="_blank">Galapagos</a></li>
                <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/2024/10/17/other-countries/"
                        target="_blank">Other countries</a></li>
                <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/blog/" target="_blank">Blog</a>
                </li>
                <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/terms-and-conditions/"
                        target="_blank">Terms and Conditions</a></li>
                <li class="nav-item menuItem"><a href="http://info@fusiontravelecuador.com/" class="rightButton"
                        target="_blank">Contact us</a></li>
            </ul>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>


    </div>
    <div style="background-color: #34c759;" class="responsiveHeader">
        <div class="container green containerHeader" style="justify-content: end; flex-direction: row!important;">
            <a class="menuButton" (click)="renderMenu()" href="javascript:void()" style="padding-right: 1em;">
                <i class="fa fa-bars fa-2x" style="padding-right: 10px;"></i><span>Menú</span>
            </a>
        </div>
    </div>
    <ul style="width: inherit; display: none;" class="menuContainer" [ngClass]="showMenu ? 'showMenu':''">
        <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/" target="_blank">Home</a></li>
        <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/destination/" target="_blank">Ecuador
                us</a></li>
        <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/2024/10/17/galapagos/"
                target="_blank">Galapagos</a></li>
        <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/2024/10/17/other-countries/"
                target="_blank">Other countries</a></li>
        <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/blog/" target="_blank">Blog</a></li>
        <li class="nav-item menuItem"><a href="https://fusiontravelecuador.com/terms-and-conditions/"
                target="_blank">Terms and Conditions</a></li>
        <li class="nav-item menuItem"><a href="http://info@fusiontravelecuador.com/" target="_blank">Contact us</a></li>

    </ul>
    <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container"
        [ngClass]="showMenu ? 'showMenu':''" style="width: inherit; display: none;">
        <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
            <i class="fa fa-heart-o"></i>
        </a>
        <span class="tooltip-text">wishlist</span>
    </div>

</nav>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppResolver } from './app.resolver';
import { AccessNotGrantedComponent } from './core/components/access-not-granted/access-not-granted.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  // main routes
  {
    path: ':companyName',
    component: LayoutComponent,
    resolve: {
      initialData: AppResolver,
    },
    children: [
      {
        path: 'search',
        loadChildren: () =>
          import('./modules/searcher/searcher.module').then(
            (m) => m.SearcherModule
          ),
      },
      {
        path: 'inquire',
        loadChildren: () =>
          import('./modules/forms-inquire/forms-inquire.module').then(
            (m) => m.FormsInquireModule
          ),
      },
      {
        path: 'charter',
        loadChildren: () =>
          import('./modules/forms-inquire/forms-inquire.module').then(
            (m) => m.FormsInquireModule
          ),
      },
      {
        path: 'cruise',
        loadChildren: () =>
          import('./modules/cruise-profile/cruise-profile.module').then(
            (m) => m.CruiseProfileModule
          ),
      },
      {
        path: 'checkout',
        loadChildren: () =>
          import('./modules/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: 'thanks',
        loadChildren: () =>
          import('./modules/thanks/thanks.module').then(
            (m) => m.ThanksModule
          ),
      },
      {
        path: 'wishlist',
        loadChildren: () =>
          import('./modules/wish-list/wish-list.module').then(
            (m) => m.WishListModule
          ),
      },
      {
        path: 'compare',
        loadChildren: () =>
          import('./modules/compare/compare.module').then(
            (m) => m.CompareModule
          ),
      },
     {
        path: ":403",
        component: AccessNotGrantedComponent,
      },
      {
        path: '**',
        redirectTo: 'search',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<!-- BARRA DE NAVEGACION -->
<div *ngIf="isDev" style="background: rgba(247, 180, 0, 0.8);color:black;padding-bottom: 5px; padding-top: 5px; position: fixed; bottom: 0; text-align: center; z-index: 9999; width: 100%; display:  block ">
    TEST ENVIRONMENT
</div>

<header>
    <app-custom-header *ngIf="!renderMenu" [company]="company" (headerHeight)="getHeight($event)" (headerHeight1)="getHeight1($event)"></app-custom-header>
    <nav *ngIf="renderMenu" class="navbar navbar-light navbar-expand-md py-md-2 fixed-top"
        [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}" id="nvbar">
        <div class="container green">
            <div *ngIf="companyParamsService.getHasLogoInformation()" class="tooltip fade show bs-tooltip-right"
                role="tooltip" id="tooltip164687" x-placement="right"
                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(266px, 24px, 0px);">
                <div class="arrow" style="top: 8px;"></div>
                <div class="tooltip-inner">Your Logo Here</div>
            </div>
            <a *ngIf="!this.companyParamsService.getWebSite()" class="navbar-brand" target="_blank" class="navbar-brand"
                data-container=".green" data-toggle="tooltip" data-placement="right" title="Your Logo Here">
                <img src="assets/img/logo.jpg" height="50" alt="logo" class="brand-logo">
            </a>
            <a *ngIf="this.companyParamsService.getWebSite()" href="{{this.companyParamsService.getWebSite()}}"
                class="navbar-brand">
                <input type="hidden" value="{{this.companyParamsService.getCompanyParams().logoFade}}" id="logoFade">
                <input type="hidden" value="{{this.companyParamsService.getCompanyParams().fadeMenu}}" id="fadeMenu">
                <img id="logoMenu" [src]=this.companyParamsService.getLogo() height="50" alt="logo"
                    class="brand-logo logoMenu">
            </a>
            <button class="navbar-toggler" type="button" style="background-color: azure;" data-toggle="collapse"
                data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                <!-- /.navbar-toggler-icon -->
            </button>
            <div class="navbar-collapse collapse justify-content-end" id="navbarNav">
                <ul class="navbar-nav" style="margin-left: 15%;">
                    <li class="nav-item dropdown py-md-2" style="display: flex;"
                        *ngFor="let item of this.companyParamsService.getListMenuOrdered()">
                        <!-- OPTION -->
                        <p class="tollfree" *ngIf="item.type === typeListMenu.OPTION">
                            <a href="{{item?.value}}" style="color: #f1f1f1;"
                                [ngStyle]="this.setCustomCSS(item.spanStyle)">
                                <span class="tollfree-item"
                                    [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}"
                                    [style]="this.setCustomCSS(item.spanStyle)"
                                    [ngStyle]="this.setCustomCSS(item.spanStyle)">{{item?.name}}
                                </span>
                            </a>
                        </p>
                        <!-- WHATSAPP -->
                        <p class="tollfree" *ngIf="item.type === typeListMenu.WHATSAPP">
                            <a href="https://api.whatsapp.com/send/?phone={{item.value}}"
                                style=" padding-top: 6px; padding-right: 10px;"
                                [ngStyle]="this.setCustomCSS(item.spanStyle)">
                                <img src="assets/icons/whatsapp.svg" height="20" alt="logo">
                            </a>
                            <span class="tollfree-item" [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}"
                                [ngStyle]="this.setCustomCSS(item.spanStyle)">{{item?.name}}</span>
                        </p>
                        <!-- PHONE -->
                        <p class="tollfree" class="tollfree" *ngIf="item.type === typeListMenu.PHONE">
                            <img src="assets/images/phone.png" alt="phone" width="25px">
                            <a style="padding-left: 5px;" class="tollfree-item" target="_blank"
                                [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}"
                                href="{{'tel:'}}{{item?.value}}"
                                [ngStyle]="this.setCustomCSS(item.spanStyle)">{{item.value}}</a>
                            <span class="tollfree-item" [ngClass]="{'navbar2': this.companyParamsService.getFadeMenu()}"
                                [ngStyle]="this.setCustomCSS(item.spanStyle)">
                                {{item?.name}}
                            </span>
                        </p>
                    </li>
                </ul>
                <div class="tooltip fade show bs-tooltip-bottom" *ngIf="companyParamsService.getHasLogoInformation()"
                    role="tooltip" id="tooltip644128" x-placement="bottom"
                    style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1051px, 44px, 0px);">
                    <div class="arrow" style="left: 71px;"></div>
                    <div class="tooltip-inner">Your Telephone Here</div>
                </div>
            </div>
            <div *ngIf="companyParamsService.getFiltersInterest()" class="wishlist tooltip-container">
                <a class="wish" (click)="goFavorites()" [ngStyle]="{'color':'#'+companyParamsService.getMainColor()}">
                    <i class="fa fa-heart-o"></i>
                </a>
                <span class="tooltip-text">wishlist</span>
            </div>
        </div>
    </nav>
</header>
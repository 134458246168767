import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements OnInit {
  @Output() public headerHeight: EventEmitter<any> = new EventEmitter();
  @Output() public headerHeight1: EventEmitter<any> = new EventEmitter();
  @Input() company!:string;
  headerHeightInput:any;
  headerHeightInput1:any;
  constructor() { }

  ngOnInit(): void {
  }

  getHeight(height:any){
    this.headerHeightInput = height;
  }

  getHeight1(height:any){
    this.headerHeightInput1 = height;
  }

  ngAfterViewInit() {
    this.headerHeight.emit(this.headerHeightInput);
    this.headerHeight1.emit(this.headerHeightInput1);
  }

}
